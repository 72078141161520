<div class="hch-textarea" [ngStyle]="styles">
  <textarea
    attr.aria-label="{{ placeholder }}"
    class="text-s"
    placeholder=" "
    [value]="value"
    (input)="changeValue($event)"
    [ngStyle]="{ background: background ? background : 'white' }"
  ></textarea>
  <label class="placeholder text-s" [ngStyle]="{ background: background ? background : 'white' }">
    {{ placeholder }}
  </label>
</div>
