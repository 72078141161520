import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'hch-request-confirm-modal',
  templateUrl: './request-confirm-modal.component.html',
  styleUrls: ['./request-confirm-modal.component.scss'],
})
export class RequestConfirmModalComponent implements OnInit, OnDestroy {
  hasError: boolean = false;
  failureMessage: string = 'Your request failed. Please try again in a few seconds.';
  successMessage: string = 'A Home Captain Concierge agent will follow up with you soon.';

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {}

  ngOnDestroy() {
    document.getElementById('hamburger-icon')?.focus();
  }
}
