import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthModalCategory } from 'src/app/core/enums/auth.enum';

@Component({
  selector: 'hch-auth-modal',
  templateUrl: './auth-modal.component.html',
  styleUrls: ['./auth-modal.component.scss'],
})
export class AuthModalComponent implements OnInit {
  // category;
  // token;
  // title = 'Great to have you back!';
  // description;
  // icon = 'assets/images/HC_Final_Volly.png';
  // status;
  // username = '';
  // hideClose = false;

  // AuthModalCategory = AuthModalCategory;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {}

  // close() {
  //   this.bsModalRef.hide();
  //   document.getElementById('hamburger-icon')?.focus();
  // }

  // changeCategory(category) {
  //   if (typeof category === 'object' && category !== null) {
  //     this.category = category.category;
  //     this.username = category.username;
  //   } else {
  //     this.category = category;
  //   }
  // }
}
