import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

// export enum STATIC_MAP_STATUS {
//   ZERO_RESULTS = 'ZERO_RESULTS',
//   OK = 'OK',
//   NOT_FOUND = 'NOT_FOUND',
//   OVER_QUERY_LIMIT = 'OVER_QUERY_LIMIT',
//   REQUEST_DENIED = 'REQUEST_DENIED',
//   INVALID_REQUEST = 'INVALID_REQUEST',
//   UNKNOWN_ERROR = 'UNKNOWN_ERROR',
// }
@Injectable({
  providedIn: 'root',
})
export class StaticMapService {
  streetViewKey = environment.STREET_VIEW_MAP_KEY;
  staticMapKey = environment.STATIC_MAP_KEY;

  constructor(private http: HttpClient) {}

  // getStaticMapMetadataWithPosition(lat, lon) {
  //   return this.http
  //     .get(
  //       'https://maps.googleapis.com/maps/api/streetview/metadata?size=1200x600&location=' +
  //         +lat +
  //         ',' +
  //         lon +
  //         '&heading=-45&pitch=42&fov=110&key=' +
  //         this.staticMapKey
  //     )
  //     .toPromise();
  // }

  getStaticMapURLWithAddress(address: string, city: string, state: string, size = '1200x600') {
    const url =
      'https://maps.googleapis.com/maps/api/streetview?size=' +
      size +
      '&location=' +
      address.split(' ').join('+') +
      ',' +
      city.split(' ').join('+') +
      ',' +
      state +
      '&fov=80&key=' +
      this.streetViewKey;
    return url;
  }

  getStaticSquareMapCity(city: string, state: string, size: number | string = 96) {
    return (
      'https://maps.googleapis.com/maps/api/staticmap?center=' +
      city +
      ',' +
      state +
      '&zoom=9&size=' +
      size +
      'x' +
      size +
      '+&key=' +
      this.staticMapKey
    );
  }

  getStaticSquareMap(latitude: number | string, longitude: number | string, size: number | string = 48) {
    return (
      'https://maps.googleapis.com/maps/api/staticmap?center=' +
      latitude +
      ',' +
      longitude +
      '&zoom=13&size=' +
      size +
      'x' +
      size +
      '+&key=' +
      this.staticMapKey
    );
  }

  // getStaticMapMetadataWithAddress(address, city, state) {
  //   return this.http
  //     .get(
  //       'https://maps.googleapis.com/maps/api/streetview/metadata?size=1200x600&location=' +
  //         address.split(' ').join('+') +
  //         ',' +
  //         city.split(' ').join('+') +
  //         ',' +
  //         state +
  //         '&fov=80&key=' +
  //         this.staticMapKey
  //     )
  //     .toPromise();
  // }
}
