import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'hch-add-filters',
  templateUrl: './add-filters.component.html',
  styleUrls: ['./add-filters.component.scss'],
})
export class AddFiltersComponent implements OnInit {
  appliedFilters = {
    filterOptions: 0,
    filters: {},
  };

  config = {
    backdrop: false,
    ignoreBackdropClick: true,
  };
  modalRef?: BsModalRef;

  @Output() changeShown = new EventEmitter();
  @Output() changeFilter = new EventEmitter();

  // Used to hide the dropdown if the search input is focused
  private _showModal: boolean | null = null;
  @Input()
  set showModal(val: boolean | null) {
    this._showModal = val;
    if (!val && this.filterDropdown) {
      this.changeShown.emit(false);
      this.filterDropdown.hide();
    }
    // if (val) {
    //   document.getElementById('search-input-location')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // }
  }
  get showModal(): boolean | null {
    return this._showModal;
  }

  @ViewChild(BsDropdownDirective) filterDropdown?: BsDropdownDirective;
  @ViewChild('addFilterDropdown') filterDropdownElement?: ElementRef;

  constructor(private modalService: BsModalService) {}

  // Used on mobile only
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
    this.changeShown.emit(true);
  }
  closeModal() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
    this.changeShown.emit(false);
  }

  ngOnInit(): void {}

  scrollTo() {
    document.getElementById('search-input-location')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    this.changeShown.emit(true);
  }

  applyFilter(event: any) {
    this.appliedFilters = event;
    this.changeFilter.emit(this.appliedFilters.filters);
  }
}
