import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

// Handles the MLS data text that is displayed in the footer

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  private mlsInfo = new BehaviorSubject<string>('');
  private mlsIcon = new BehaviorSubject<string>('');
  private listingUrl = new BehaviorSubject<string>('');

  constructor() {}

  setListingUrl(url: string) {
    this.listingUrl.next(url);
  }
  setMlsInfo(mlsInfo: string) {
    this.mlsInfo.next(mlsInfo);
  }
  setMlsIcon(mlsIcon: string) {
    if (mlsIcon && mlsIcon.includes('listhub')) {
      const url = this.getIconPathFromUrl(mlsIcon);
      this.mlsIcon.next(url);
    } else if (mlsIcon) {
      this.mlsIcon.next(mlsIcon);
    } else {
      this.mlsIcon.next('');
    }
  }

  getMlsInfo() {
    return this.mlsInfo;
  }
  getMlsIcon() {
    return this.mlsIcon;
  }
  getListingUrl() {
    return this.listingUrl;
  }

  getIconPathFromUrl(url: string) {
    if (!url.includes('listhub')) {
      return url;
    }

    let pieces = url.split('/');
    let fileName = pieces[pieces.length - 1];
    // if (environment.production) {
    return 'https://cdn.myvolly.io/homecaptain/broker-logos/' + fileName;
    // }
    // return 'https://cdn.uat.myvolly.io/homecaptain/broker-logos/' + fileName;
  }
}
