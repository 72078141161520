import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, switchMap, of } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Listing, ListingModel } from '../models/listing.model';
import { FooterService } from './footer.service';
import { UrlParserService } from './url-parser.service';

@Injectable({
  providedIn: 'root',
})
export class RealStaqService {
  distance = '1000mi';

  urlRealStaq: string = '';
  keyRealStaq: string = '';

  constructor(
    private _http: HttpClient,
    private urlParserService: UrlParserService,
    private footerService: FooterService
  ) {
    this.keyRealStaq = environment.MLS_API_KEY;
    this.urlRealStaq = environment.MLS_API_BASE_URL + '/api/mls-search';
  }

  get provider() {
    return environment.API_TYPE;
  }

  private _getDataFromRealStaqListing(data: any, mls: any): Listing {
    // Determine agent info
    let agentFullName = '';
    let agentEmail = '';
    let agentPreferredPhone = '';
    let agentOfficePhone = '';
    let agentOfficeName = '';
    if (data.agents.listing_agent) {
      const agent = data.agents.listing_agent;
      agentEmail = agent.email;
      agentFullName = agent.name;
      agentPreferredPhone = agent.phone;
      if (agent.office) {
        agentOfficePhone = agent.office.phone;
        agentOfficeName = agent.office.name;
      }
    } else if (data.agents.co_listing_agent) {
      const agents = data.agents.co_listing_agent;
      agentEmail = agents.email;
      agentFullName = agents.name;
      agentPreferredPhone = agents.phone;
      if (agents.office) {
        agentOfficePhone = agents.office.phone;
        agentOfficeName = agents.office.name;
      }
    }

    return {
      agentFullName,
      agentEmail,
      agentPreferredPhone,
      agentOfficePhone,
      agentOfficeName,
      address: data.address,
      bathrooms: data.bathrooms,
      bedrooms: data.bedrooms,
      city: data.city,
      country: data.country,
      description: data.description,
      id: data.id,
      lotSizeArea: data.lot_size,
      lotSizeUnits: 'acres',
      photos: data.photos.map((p: any) => p.large) as string[],
      listingId: data.id,
      modificationTimestamp: data.list_date,
      latitude: data.location && data.location[1],
      longitude: data.location && data.location[0],
      mlsId: data.mls_listing_id,
      mlsBrokerName: mls && mls.name,
      mlsDisclaimer: mls && mls.compliance_text,
      mlsLogo: mls && (mls.idx_logo || mls.idx_logo_small),
      price: data.price,
      propertyType: data.property_type,
      propertySubType: '',
      squareFeet: data.square_feet,
      state: data.state,
      standardStatus: data.status,
      virtualTour: data.virtual_tour,
      yearBuilt: data.year_built,
      zipcode: data.zip,
    } as Listing;
  }

  private _getDataFromRealStaq(data: any) {
    return of(null).pipe(
      switchMap(() => {
        const mls = !!data.content?.mls?.length ? data.content.mls[0] : null;

        if (data && data.content && data.content.listings && data.content.listings[0]) {
          let listing = data.content.listings[0];
          if (
            listing.state.toLowerCase().includes('nj') &&
            listing.city.toLowerCase().includes('monroe') &&
            listing.city.toLowerCase().includes('township')
          ) {
            return this.getMLSDisclaimer(20);
          }
        }
        return of(mls);
      }),
      map((mls: any) => {
        let contentListings: Listing[] = [];

        if (mls) {
          this.footerService.setMlsInfo(mls.compliance_text);
          this.footerService.setMlsIcon(mls.idx_logo || mls.idx_logo_small);
        }
        for (let listing of data.content.listings) {
          contentListings.push(this._getDataFromRealStaqListing(listing, mls));
        }
        return {
          pageNumber: data.page_number + 1,
          elementsPerPage: data.elements_per_page,
          totalElements: data.total_elements,
          totalPages: data.total_pages,
          listings: contentListings,
        } as ListingModel;
      })
    );
  }

  getSimilarListingsById(id: string, size = '3', distance = '5mi', tolerance = '0.2') {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('x-api-key', this.keyRealStaq);

    let params = new HttpParams();
    params = params.set('size', size);
    params = params.set('distance', distance);
    params = params.set('tolerance', tolerance);
    params = params.set('view', 'detailed');

    return this._http
      .get(this.urlRealStaq + '/v1/listings/' + id + '/similar', {
        headers: headers,
        params: params,
      })
      .pipe(
        switchMap((res: any) => {
          return this._getDataFromRealStaq({ content: res });
        })
      );
  }

  getListingById(id: string) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('x-api-key', this.keyRealStaq);

    return this._http
      .get(this.urlRealStaq + '/v1/listings/' + id, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          const mls = !!res.content?.mls?.length ? res.content.mls[0] : res.mls ? res.mls : null;
          return this._getDataFromRealStaqListing(res, mls);
        })
      );
  }

  getListings() {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('x-api-key', this.keyRealStaq);

    let params = new HttpParams();
    let _urlObject = this.urlParserService.urlObject;

    for (let key in _urlObject) {
      if (key != 'zoom') {
        if (key == 'page') {
          const pageNumber = parseInt(_urlObject[key]) - 1;
          params = params.append('number', pageNumber.toString());
        } else params = params.append(key, _urlObject[key]);
      }
    }
    params = params.append('size', '20');
    if (!params.get('minPrice')) {
      params = params.append('minPrice', '10000')
    }
    return this._http
      .get(this.urlRealStaq + '/v1/listings', {
        headers: headers,
        params: params,
      })
      .pipe(
        switchMap((res: any) => {
          return this._getDataFromRealStaq(res);
        })
      );
  }

  getNearByListings(latitude: string | number, longitude: string | number) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('x-api-key', this.keyRealStaq);

    let params = new HttpParams();
    params = params.set('position', latitude + ',' + longitude);
    params = params.set('distance', this.distance);
    params = params.set('size', '10');

    return this._http
      .get(this.urlRealStaq + '/v1/listings', {
        headers: headers,
        params: params,
      })
      .pipe(
        switchMap((res: any) => {
          return this._getDataFromRealStaq(res);
        })
      );
  }

  getListingsWithQuery(queryParams: any) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('x-api-key', this.keyRealStaq);

    let params = new HttpParams();
    for (let key in queryParams) {
      params = params.set(key, queryParams[key]);
    }
    params = params.set('size', '20');

    return this._http
      .get(this.urlRealStaq + '/v1/listings', {
        headers: headers,
        params: params,
      })
      .pipe(
        switchMap((res: any) => {
          return this._getDataFromRealStaq(res);
        })
      );
  }

  getMLSDisclaimer(mlsid: number | string) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('x-api-key', this.keyRealStaq);

    return this._http.get(environment.MLS_API_BASE_URL + '/api/mls/v1/mls/' + mlsid, {
      headers: headers,
    });
  }

  searchLocations(input: string) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('x-api-key', this.keyRealStaq);

    let params = new HttpParams();
    params = params.set('size', '10');
    params = params.set('entities', 'city,zip');
    params = params.set('input', input);

    return this._http.get(environment.MLS_API_BASE_URL + '/api/typeahead/v1/autocomplete', {
      headers: headers,
      params: params,
    });
  }

  searchAddresses(input: string) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('x-api-key', this.keyRealStaq);

    let params = new HttpParams();
    params = params.set('size', '10');
    params = params.set('entities', 'address');
    params = params.set('input', input);

    return this._http.get(environment.MLS_API_BASE_URL + '/api/typeahead/v1/autocomplete', {
      headers: headers,
      params: params,
    });
  }

  getFavoritedListings(ids: string[], sort: string) {
    // if (ids.length == 0) return of(null);

    // Array<string>
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('x-api-key', this.keyRealStaq);

    let params = new HttpParams();
    params = params.set('listings', ids.toString()); // TODO verify this works
    params = params.set('sort', sort);

    return of(null).pipe(
      switchMap(() => {
        if (ids.length == 0) return of(null);
        return this._http
          .get(this.urlRealStaq + '/v1/listings', {
            headers: headers,
            params: params,
          })
          .pipe(
            switchMap((res: any) => {
              return this._getDataFromRealStaq(res);
            })
          );
      })
    );
  }

  getDemoListings() {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('x-api-key', this.keyRealStaq);

    let params = new HttpParams();
    params = params.set('city', 'Beach Haven');
    params = params.set('state', 'NJ');
    params = params.set('size', '20');

    return this._http
      .get(this.urlRealStaq + '/v1/listings', {
        headers: headers,
        params: params,
      })
      .pipe(
        switchMap((res: any) => {
          return this._getDataFromRealStaq(res);
        })
      );
  }
}
