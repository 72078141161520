import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { delay, filter } from 'rxjs/operators';

export interface IntersectionObserverSubjectType {
  entry: IntersectionObserverEntry;
  observer: IntersectionObserver;
}
export type EmittedType = {
  element: HTMLElement;
  visible: boolean;
};

@Directive({
  selector: '[observeVisibility]',
})
export class ObserveVisibilityDirective implements OnDestroy, OnInit, AfterViewInit {
  @Input() debounceTime = 0;
  @Input() threshold = 0.66;

  @Output() visible = new EventEmitter<EmittedType>();

  private observer: IntersectionObserver | undefined;
  private subject$ = new Subject<IntersectionObserverSubjectType>();

  constructor(private element: ElementRef) {}

  ngOnInit() {
    this.createObserver();
  }

  ngAfterViewInit() {
    this.startObservingElements();
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = undefined;
    }

    // this.subject$.next();
    this.subject$.complete();
  }

  private isVisible(element: HTMLElement): Promise<boolean> {
    return new Promise((resolve) => {
      const observer = new IntersectionObserver(([entry]) => {
        const inView = entry.intersectionRatio >= this.threshold;
        resolve(inView);
        // observer.disconnect()
      });

      observer.observe(element);
    });
  }

  private createObserver() {
    const options = {
      rootMargin: '0px',
      threshold: this.threshold,
    };

    const isIntersecting = (entry: IntersectionObserverEntry) =>
      entry.isIntersecting || entry.intersectionRatio > this.threshold;

    this.observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        this.subject$.next({ entry, observer });
        // if (isIntersecting(entry)) {
        //     this.subject$.next({ entry, observer })
        // }
      });
    }, options);
  }

  private startObservingElements() {
    if (!this.observer) return;

    this.observer.observe(this.element.nativeElement);

    this.subject$
      .pipe(delay(this.debounceTime), filter(Boolean))
      .subscribe(async ({ entry, observer }: IntersectionObserverSubjectType) => {
        const target = entry.target as HTMLElement;
        const isStillVisible = await this.isVisible(target);
        this.visible.emit({ element: target, visible: isStillVisible });

        // if (isStillVisible) {
        //     this.visible.emit(target)
        //     observer.unobserve(target)
        // }
      });
  }
}
