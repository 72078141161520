import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'obs',
})
export class ObsPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    console.log(value, args);
  }
}
