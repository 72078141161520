import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatest, Observable, map } from 'rxjs';
import { Unsubscriber } from '../extenders/unsubscriber';

import { AuthService } from '../services/auth.service';
import { ModalService } from '../services/modal.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends Unsubscriber implements CanActivate {
  constructor(private authService: AuthService, private modalService: ModalService, private router: Router) {
    super();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.authorized.getValue() && !this.authService.refreshing.getValue()) {
      return true;
    }
    this.addSubscription = combineLatest([this.authService.authorized, this.authService.refreshing])
      .pipe(
        map(([authorized, refreshing]) => {
          if (refreshing) return;
          if (authorized) {
            return this.router.navigateByUrl(state.url);
          }
          const modal = this.modalService.showLoginModal();
          this.addSubscription = modal.onHidden?.subscribe(() => {
            if (this.authService.authorized.getValue()) {
              this.router.navigateByUrl(state.url);
            }
          });
          return;
        })
      )
      .subscribe();
    return false;
  }
}
