<div
  dropdown
  #dropdown="bs-dropdown"
  class="hch-floating-dropdown"
  [ngStyle]="styles"
  (onShown)="emitIsOpen(true)"
  (onHidden)="emitIsOpen(false)"
  [isDisabled]="disabled"
  [style.opacity]="disabled ? '0.5' : '1'"
>
  <button
    dropdownToggle
    class="hch-btn hch-btn-secondary dropdown-toggle"
    aria-controls="dropdown-basic"
    aria-expanded="dropdown.isOpen"
    [id]="'dropdown-' + category"
    (click)="focusSelectedItem()"
    (keydown.enter)="focusSelectedItem()"
    (keydown.space)="focusSelectedItem()"
  >
    {{ selectedItem.label }}
  </button>
  <label *ngIf="placeholder" class="placeholder text-s" style="opacity: 1">
    {{ placeholder }}
  </label>
  <div *ngIf="category">
    <ul
      *dropdownMenu
      class="dropdown-menu"
      role="listbox"
      (keydown.arrowdown)="focusNextItem($event)"
      (keydown.arrowup)="focusPrevItem($event)"
      (keydown.tab)="focusNextItem($event)"
      (keydown.shift.tab)="focusPrevItem($event)"
    >
      <li
        *ngFor="let item of items; let i = index"
        role="option"
        tabindex="0"
        [id]="'dropdown-' + category + '-item-' + i"
        [ngClass]="{
          selected: selectedItem && item.value === selectedItem.value
        }"
        (click)="selectItem(item); dropdown.hide()"
        (keydown.enter)="selectItem(item); dropdown.hide()"
      >
        {{ item.label }}
      </li>
    </ul>
  </div>
</div>
