<div
  role="group"
  aria-labelledby="bathrooms-label"
  class="position-relative"
  dropdown
  *ngIf="!mobile"
  #dropdownBaths="bs-dropdown"
>
  <button
    id="baths-filter"
    class="btn btn-outline-primary btn-md dropdown-toggle"
    dropdownToggle
    aria-label="Bathrooms Range"
  >
    {{ selectedBath.value == -1 ? 'Baths' : selectedBath.label + ' Baths' }}
  </button>
  <div *dropdownMenu class="dropdown-menu">
    <div class="dropdown-body">
      <div
        *ngFor="let item of bathsOptions"
        class="option"
        tabindex="0"
        (click)="changeBathRoom(item); dropdownBaths.hide()"
        (keydown.enter)="changeBathRoom(item); dropdownBaths.hide()"
        [ngClass]="{ selected: selectedBath.value === item.value }"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</div>

<div *ngIf="mobile">
  <hch-stepper
    [options]="bathsOptions"
    [selectedOption]="selectedBath"
    (changeEvent)="changeBathRoom($event)"
  ></hch-stepper>
</div>
