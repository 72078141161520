import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[numberFormat]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberFormatDirective),
      multi: true,
    },
  ],
})
export class NumberFormatDirective implements ControlValueAccessor {
  private onChange: (val: string) => void = (val) => {};
  private onTouched: () => void = () => {};
  private value: string = '';

  formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  @HostListener('input', ['$event.target.value'])
  onInputChange(value: string) {
    let filteredValue: string = filterValue(value);
    const num = Number(filteredValue);
    if (!isNaN(num)) {
      filteredValue = this.formatter.format(num);
    }
    this.updateTextInput(filteredValue, this.value !== filteredValue);
  }

  @HostListener('blur')
  onBlur() {
    this.onTouched();
  }

  private updateTextInput(value: string, propagateChange: boolean) {
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', value);
    if (propagateChange) {
      this.onChange(value);
    }
    this.value = value;
  }

  // ControlValueAccessor Interface
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }

  writeValue(value: any): void {
    value = value ? String(value) : '';
    this.updateTextInput(value, false);
  }
}

@Directive({
  selector: '[numberSeparator]',
})
export class NumberSeparatorDirective {
  private value: string = '';

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  @HostListener('input', ['$event.target.value'])
  onInputChange(value: string) {
    let filteredValue: string = filterValue(value);
    const num = Number(filteredValue);
    if (!isNaN(num)) {
      filteredValue = this.formatter.format(num);
    }
    this.updateTextInput(filteredValue, this.value !== filteredValue);
  }

  private updateTextInput(value: string, propagateChange: boolean) {
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', value);
    // if (propagateChange) {
    //   this.onChange(value);
    // }
    this.value = value;
  }
}

function filterValue(value: string): string {
  return value.replace(/[^0-9]*/g, '');
}
