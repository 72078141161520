import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringmatch',
})
export class StringMatchPipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): any {
    if (value) return stringMatch(value, args);
    return null;
  }
}

export function stringMatch(value: string, args: unknown[]) {
  const searchTerm = String(args[0]);
  const matches = value.match(new RegExp(searchTerm, 'i'));
  if (!matches) return [value];
  const unmatchedStart = value.substring(0, matches.index || 0);
  const unmatchedEnd = value.substring((matches.index || 0) + searchTerm.length);
  return [unmatchedStart, matches[0], unmatchedEnd];
}
