import { Provider } from './listing.model';

export interface UserModel {
  id: number;
  campaignid: number | string;
  fullName: string;
  email: string;
  emailVerified: boolean;
  phoneNumber: string;
  provider: string;
  favorites: UserFavorite[];
  createdAt: string;
  updatedAt: string;
  searches: UserSearch[];
  firstSearch: string;
  currentStateOfResidence: string;
  jungoId?: string;
}

export interface UserActivity {
  city: string;
  state: string;
  address: string;
  zipcode: string;

  // For Request: User data
  fullName?: string;
  phoneNumber?: string;
  email?: string;
  firstName?: string;
  lastName?: string;

  // For Activities: Sell home
  interest?: string;
  timeFrame?: string;
  discountedListing?: string;
  needAgent?: boolean;

  // For Home Valuation (sell page) and Request Showing (buy page)
  requestDate?: string;

  // For Activities: Listing data
  id?: string;
  url?: string; // mls url
  location?: number[];
  latitude?: number;
  longitude?: number;
  listingId?: string;
  propertyType?: string;
  price?: string;
  standardStatus?: string;
  description?: string;
  bedrooms?: number;
  bathrooms?: number;
  yearBuilt?: number;
  squareFeet?: number;
  lotSizeArea?: number;
  modificationTimestamp?: string;
  listingDate?: string;
  provider?: string;

  // For Home Value email
  lead_first_name?: string;
  low_value?: string;
  mid_value?: string;
  high_value?: string;
  increased?: string;
  increase_value?: string;
  reportUrl?: string;
}

export interface UserSearch {
  name: string;
  [key: string]: string;
}

export interface UserFavorite {
  id: string;
  provider: Provider;
}

export interface UserRequestMR {
  clientMessage: string;
  loEmail: string;
  loName: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface UserRequest {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  jungoId?: string;
  message?: string;
  address?: string;
  timeFrame?: string;
  interest?: string;
  needAgent?: boolean;
  requestDate?: string;
}

export enum UserRequestsE {
  NEED_REAL_ESTATE_AGENT = 'need-real-estate-agent',
  READY_TO_GET_STARTED = 'ready-to-get-started',
  HAVE_ME_CALL_YOU = 'have-me-call-you',
  HAVE_ME_CALL_YOU_MR = 'have-me-call-you-mr',
  SCHEDULE_CALL = 'schedule-call',
  GET_SUPPORT = 'get-support',
  GET_INFO = 'get-info',
}
