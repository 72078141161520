import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform(value: any): any {
    let cleaned = ('' + value).replace(/\D/g, '');
    let match;
    // Without country code
    if (cleaned.length == 10) {
      match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    }
    // With country code
    if (cleaned.length == 11) {
      match = cleaned.match(/^(?:\d)(\d{3})(\d{3})(\d{4})$/);
    }
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }
}
