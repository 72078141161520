<div dropdown #dropdownHam="bs-dropdown" class="position-relative" role="navigation" id="dropdown-menu-container">
  <button
    dropdownToggle
    id="hamburger-icon"
    class="hamburger-icon"
    aria-haspopup="true"
    aria-controls="dropdown-basic"
    [attr.aria-label]="dropdownHam.isOpen ? 'Close navigation menu' : 'Open navigation menu'"
    [attr.aria-expanded]="dropdownHam.isOpen"
    role="button">
    <span></span>
    <span></span>
    <span></span>
  </button>
  <ul
    *dropdownMenu
    class="dropdown-menu dropdown-menu-right"
    id="dropdown-basic"
    role="menu"
    aria-labelledby="dropdown-menu-container">
    <hch-user-menu></hch-user-menu>
  </ul>
</div>
