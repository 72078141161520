import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SearchParams, UrlParserService } from 'src/app/core/services/url-parser.service';
import { DropdownItem, maxPriceRangesDropdown, minPriceRangesDropdown } from 'src/app/core/models/dropdown.model';
import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';
import { RoutingService } from 'src/app/core/services/routing.service';

@Component({
  selector: 'hch-price-dropdown',
  templateUrl: './price-dropdown.component.html',
  styleUrls: ['./price-dropdown.component.scss'],
})
export class PriceDropdownComponent extends Unsubscriber implements OnInit {
  @Input() mobile = false;
  @Output() change = new EventEmitter();

  minPriceRanges = minPriceRangesDropdown;
  maxPriceRanges = maxPriceRangesDropdown;
  minPrice: DropdownItem = minPriceRangesDropdown[0];
  maxPrice: DropdownItem = maxPriceRangesDropdown[0];

  initialMinPrice: DropdownItem = minPriceRangesDropdown[0];
  initialMaxPrice: DropdownItem = maxPriceRangesDropdown[0];

  error: string = '';

  get url() {
    return [this.routingService.buyUrl.getValue()];
  }

  constructor(
    private urlParserService: UrlParserService,
    private route: ActivatedRoute,
    private routingService: RoutingService
  ) {
    super();
  }

  ngOnInit(): void {
    this.addSubscription = this.route.queryParams.subscribe((params) => {
      let minPrice = Number(params['minPrice']);
      let maxPrice = Number(params['maxPrice']);

      if (minPrice) {
        this.minPrice = { label: this.formatLabel(minPrice), value: minPrice };
        // Update max price ranges with No Max and values limited by minimum
        this.maxPriceRanges = [
          maxPriceRangesDropdown[0],
          ...maxPriceRangesDropdown.filter((price) => Number(price.value) >= Number(this.minPrice.value)),
        ];
      } else {
        this.minPrice = this.initialMinPrice;
        this.maxPriceRanges = maxPriceRangesDropdown;
      }
      if (maxPrice) {
        this.maxPrice = { label: this.formatLabel(maxPrice), value: maxPrice };
        // Update min price ranges with No Max and values limited by maximum
        this.minPriceRanges = [
          ...minPriceRangesDropdown.filter((price) => Number(price.value) <= Number(this.maxPrice.value)),
        ];
      } else {
        this.maxPrice = this.initialMaxPrice;
        this.minPriceRanges = minPriceRangesDropdown;
      }
    });
  }

  tabPress() {
    setTimeout(() => {
      document.getElementById('dropdown-MAX_PRICE')?.focus();
    }, 0);
  }

  shifttabPress() {
    setTimeout(() => {
      document.getElementById('dropdown-MIN_PRICE')?.focus();
    }, 0);
  }

  // focusPrevInput() {
  //   setTimeout(() => {
  //     document.getElementById('search-input')?.focus();
  //   }, 0);
  // }
  // focusNextInput() {
  //   setTimeout(() => {
  //     document.getElementById('beds-input')?.focus();
  //   }, 0);
  // }

  formatLabel(price: number) {
    return '$' + price.toLocaleString('en-US');
  }

  changeMinPrice(value: any) {
    if (typeof value == 'string') {
      value = Number(value.replace(/[^0-9]/g, ''));
    }
    this.error = '';
    if (value == 0) {
      this.urlParserService.removeParam(SearchParams.minPrice, this.url);
      this.minPrice = this.initialMinPrice;
    } else {
      if (this.maxPrice.value != 0 && this.maxPrice.value < value) {
        this.urlParserService.removeParam(SearchParams.minPrice, this.url);
        this.minPrice = this.initialMinPrice;
        this.error = 'Min Price cannot be more than Max Price';
        return;
      }
      if (value < 5000) {
        this.urlParserService.removeParam(SearchParams.minPrice, this.url);
        this.minPrice = this.initialMinPrice;
        this.error = 'Min Price cannot be less than $5,000';
        return;
      }
      this.urlParserService.addParam(SearchParams.minPrice, Number(value), this.url);
      this.minPrice = { label: this.formatLabel(value), value };
    }
  }

  changeMaxPrice(value: any) {
    if (typeof value == 'string') {
      value = Number(value.replace(/[^0-9]/g, ''));
    }
    this.error = '';
    if (value == 0) {
      this.urlParserService.removeParam(SearchParams.maxPrice, this.url);
      this.maxPrice = this.initialMaxPrice;
    } else {
      if (this.minPrice.value != 0 && this.minPrice.value > value) {
        this.urlParserService.removeParam(SearchParams.maxPrice, this.url);
        this.maxPrice = this.initialMaxPrice;
        this.error = 'Max Price cannot be less than Min Price';
        return;
      }
      if (value < 5000) {
        this.urlParserService.removeParam(SearchParams.maxPrice, this.url);
        this.maxPrice = this.initialMaxPrice;
        this.error = 'Max Price cannot be less than $5,000';
        return;
      }
      this.urlParserService.addParam(SearchParams.maxPrice, Number(value), this.url);
      this.maxPrice = { label: this.formatLabel(value), value };
    }
  }

  changeEvent() {
    this.change.emit({ minPrice: this.minPrice.value, maxPrice: this.maxPrice.value });
  }
}
