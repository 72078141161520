import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';
import { DropdownItem } from 'src/app/core/models/dropdown.model';
import { RoutingService } from 'src/app/core/services/routing.service';
import { SearchParams, UrlParserService } from 'src/app/core/services/url-parser.service';

@Component({
  selector: 'hch-beds-dropdown',
  templateUrl: './beds-dropdown.component.html',
  styleUrls: ['./beds-dropdown.component.scss'],
})
export class BedsDropdownComponent extends Unsubscriber implements OnInit {
  @Input() mobile = false;
  @Output() changeEvent = new EventEmitter();

  url = [this.routingService.buyUrl.getValue()];
  bedsOptions: DropdownItem[] = [
    { label: 'Any', value: -1 },
    { label: 'Studio+', value: 0 },
    { label: '1+', value: 1 },
    { label: '2+', value: 2 },
    { label: '3+', value: 3 },
    { label: '4+', value: 4 },
    { label: '5+', value: 5 },
  ];
  selectedBed: DropdownItem = { label: 'Any', value: -1 };

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private urlParserService: UrlParserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.addSubscription = this.route.queryParams.subscribe((params) => {
      let bedroom = params['minBedrooms'];
      if (bedroom) {
        const filtered = this.bedsOptions.filter((item) => item.value == bedroom);
        if (filtered.length > 0) {
          this.selectedBed = filtered[0];
        } else {
          this.selectedBed = this.bedsOptions[0];
        }
      } else {
        this.selectedBed = this.bedsOptions[0];
      }
    });
  }

  changeBedRoom(event: any) {
    this.selectedBed = event;
    if (this.selectedBed.value == -1) {
      this.urlParserService.removeParam(SearchParams.minBedrooms);
    } else {
      this.urlParserService.addParam(SearchParams.minBedrooms, this.selectedBed.value, this.url);
    }
  }
}
