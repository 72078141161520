<div class="position-relative" *ngIf="!mobile" dropdown [insideClick]="true">
  <button class="btn btn-outline-primary btn-md dropdown-toggle" dropdownToggle>
    {{
      minPrice.label == 'No Min' && maxPrice.label == 'No Max'
        ? 'Price'
        : (minPrice.label | currency3) + '-' + (maxPrice.label | currency3)
    }}
  </button>
  <div *dropdownMenu class="dropdown-menu dropdown-body">
    <ng-container *ngTemplateOutlet="priceRangeDropdown"></ng-container>
  </div>
</div>

<div *ngIf="mobile">
  <ng-container *ngTemplateOutlet="priceRangeDropdown"></ng-container>
</div>

<ng-template #priceRangeDropdown>
  <div class="d-flex justify-content-between align-items-center">
    <hch-editable-floating-dropdown
      placeholder="Min Price"
      category="MIN_PRICE"
      mask="separator.0"
      prefix="$"
      thousandSeparator=","
      [items]="minPriceRanges"
      [value]="minPrice"
      (changes)="changeMinPrice($event)"
      (tabPress)="tabPress()"
    ></hch-editable-floating-dropdown>
    <!-- (shifttabPress)="focusPrevInput()" -->
    <div class="range"></div>
    <hch-editable-floating-dropdown
      placeholder="Max Price"
      category="MAX_PRICE"
      mask="separator.0"
      prefix="$"
      thousandSeparator=","
      [items]="maxPriceRanges"
      [value]="maxPrice"
      (changes)="changeMaxPrice($event)"
      (shifttabPress)="shifttabPress()"
    ></hch-editable-floating-dropdown>
    <!-- (tabPress)="focusNextInput()" -->
  </div>
  <div class="error" *ngIf="error">
    <p>{{ error }}</p>
  </div>
</ng-template>
