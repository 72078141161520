import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeago',
})
export class TimeagoPipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): any {
    if (value) return timeSince(value);
    return null;
  }
}

function timeSince(date: Date | number | string) {
  if (typeof date == 'object') {
    date = date.valueOf();
  } else if (typeof date == 'string') {
    date = new Date(date).valueOf();
  }

  let seconds = Math.floor((new Date().valueOf() - date) / 1000);

  let interval = seconds / 31536000;
  let time = 0;

  if (interval > 1) {
    time = Math.floor(interval);
    return `${time} year${time > 1 ? 's' : ''}`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    time = Math.floor(interval);
    return `${time} month${time > 1 ? 's' : ''}`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    time = Math.floor(interval);
    return `${time} day${time > 1 ? 's' : ''}`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    time = Math.floor(interval);
    return `${time} hour${time > 1 ? 's' : ''}`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    time = Math.floor(interval);
    return `${time} minute${time > 1 ? 's' : ''}`;
  }
  time = Math.floor(interval);
  return `${time} second${time > 1 ? 's' : ''}`;
}
