import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Loan {
  principal: number;
  interest: number;
  totalInterest: number;
  totalPrincipal: number;
}

export interface SavingValues {
  cashSavings: number;
  differenceInEquity: number;
  totalSavings: number;
}

@Injectable({
  providedIn: 'root',
})
export class MortgageCalculatorService {
  monthlyPayment = new BehaviorSubject<number>(0);

  constructor() {}

  // https://www.thebalance.com/calculate-mortgage-315668

  findPayment(loanAmount: number, interestRate: number, periods: number) {
    return (
      (loanAmount * interestRate * Math.pow(1 + interestRate, periods)) / (Math.pow(1 + interestRate, periods) - 1)
    );
  }

  setMonthlyPayment(loanAmount: number, interestRate: number, term: number) {
    const amount = this.findPayment(loanAmount, interestRate / 100 / 12, term * 12);
    if (this.monthlyPayment.getValue() !== amount) this.monthlyPayment.next(amount);
    return amount;
  }

  // loanAmount $2,000,000
  // interest 9%
  // term 30 years
  getMonthlyPayment() {
    return this.monthlyPayment;
    // this.setMonthlyPayment(loanAmount, interestRate, term);
    // return this.findPayment(loanAmount, interestRate / 100 / 12, term * 12);
  }

  getYearlyPaymentList(loanAmount: number, interestRate: number, term: number): Loan[] {
    let totalInterest = 0;
    let totalPrincipal = 0;
    let startingBalance = loanAmount;
    this.setMonthlyPayment(loanAmount, interestRate, term);
    let loans: Loan[] = [];

    for (let index = 0; index < term; index += 1) {
      let yearlyPrincipal = 0;
      let yearlyInterest = 0;
      for (let month = 0; month < 12; month++) {
        const interest = (startingBalance * interestRate) / 100 / 12;
        const principal = this.monthlyPayment.getValue() - interest;
        yearlyPrincipal += principal;
        yearlyInterest += interest;
        startingBalance -= principal;
        if (startingBalance < 0) {
          startingBalance = 0;
          break;
        }
      }
      totalPrincipal += yearlyPrincipal;
      totalInterest += yearlyInterest;
      loans.push({
        principal: yearlyPrincipal,
        interest: yearlyInterest,
        totalInterest: totalInterest,
        totalPrincipal: totalPrincipal,
      });
    }
    return loans;
  }
}
