import { Component, Input, OnInit } from '@angular/core';
import { finalize, of, switchMap } from 'rxjs';

import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';
import { Listing, Provider } from 'src/app/core/models/listing.model';
import { ActivityService } from 'src/app/core/services/activity.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { StatsService } from 'src/app/core/services/stats.service';

@Component({
  selector: 'hch-favorite-icon',
  templateUrl: './favorite-icon.component.html',
  styleUrls: ['./favorite-icon.component.scss'],
})
export class FavoriteIconComponent extends Unsubscriber implements OnInit {
  @Input() listing?: Listing | null;
  @Input() provider?: Provider | null;
  @Input() display?: string | 'TEXT' | 'ICON';

  updating = false;

  private _savedCard: boolean = false;

  // @Input() savedCard: boolean = false;
  @Input()
  set savedCard(saved: boolean) {
    this._savedCard = saved;
  }
  get savedCard(): boolean {
    return this._savedCard;
  }

  constructor(
    private authService: AuthService,
    private activityService: ActivityService,
    private modalService: ModalService,
    private statsService: StatsService
  ) {
    super();
  }

  ngOnInit(): void {}

  /**
   * Toggles the user's favorite house
   * If not logged in, show the Sign In modal
   */
  favoriteItem() {
    this.updating = true;
    // this.savedCard = !this.savedCard;

    if (this.authService.authorized.getValue()) {
      this.addSubscription = this.favorite().subscribe();
    } else {
      const modal = this.modalService.showLoginModal();
      this.addSubscription = modal.onHidden?.subscribe(() => {
        if (this.authService.authorized.getValue()) {
          setTimeout(() => {
            this.addSubscription = this.favorite().subscribe();
          }, 10);
        }
      });
    }
  }

  private favorite() {
    const provider = this.provider!;
    const id = this.listing!.listingId!;

    return of(null).pipe(
      switchMap(() => {
        return this.activityService.toggleFavoriteItem(id, provider, this.listing!);
      }),
      switchMap(() => {
        return this.statsService.saveListing(id, provider, this.savedCard);
      }),
      finalize(() => {
        // this.favoriteEvent.emit([id, this.savedCard]);
        // this.savedCard = !this.savedCard;
        this.updating = false;
      })
    );
  }
}
