import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';

import {
  DropdownItem,
  listingStatuses,
  lotSizesDropdown,
  maxFootagesDropdown,
  maxPriceRangesDropdown,
  minFootagesDropdown,
  minPriceRangesDropdown,
  propertyTypes,
} from 'src/app/core/models/dropdown.model';
import { RoutingService } from 'src/app/core/services/routing.service';
import { SearchParams, UrlParserService } from 'src/app/core/services/url-parser.service';

@Component({
  selector: 'hch-more-dropdown',
  templateUrl: './more-dropdown.component.html',
  styleUrls: ['./more-dropdown.component.scss'],
})
export class MoreDropdownComponent extends Unsubscriber implements OnInit {
  @Input() mobile = false;
  @Output() applyFiltersEvent = new EventEmitter();
  @Output() closeEvent = new EventEmitter();
  @Output() changeValid = new EventEmitter();

  url = [this.routingService.buyUrl.getValue()];
  filterOptions = 0;

  lotSizes = lotSizesDropdown;
  minPriceRanges = minPriceRangesDropdown;
  maxPriceRanges = maxPriceRangesDropdown;
  propertyTypes = propertyTypes;
  status = listingStatuses;

  minFootages = minFootagesDropdown;
  maxFootages = maxFootagesDropdown;

  lotSize = lotSizesDropdown[0];
  minPrice = minPriceRangesDropdown[0];
  maxPrice = maxPriceRangesDropdown[0];
  minFootage = minFootagesDropdown[0];
  maxFootage = maxFootagesDropdown[0];

  minYear: string | number = 'No Min';
  maxYear: string | number = 'No Max';
  thisYear = new Date().getFullYear();

  isEnableApply = true;

  params: Params | undefined;
  currentParams: Params | undefined;

  constructor(
    private urlParserService: UrlParserService,
    private route: ActivatedRoute,
    private routingService: RoutingService
  ) {
    super();
  }

  ngOnInit(): void {
    this.checkAppliedFilters();

    this.addSubscription = this.route.queryParams.subscribe((params) => {
      this.currentParams = params;

      if (params[SearchParams.propertyTypes]) {
        let propertyTypes = params[SearchParams.propertyTypes];
        this.propertyTypes.map((item) => {
          if (propertyTypes.indexOf(item.value) >= 0) {
            item.selected = true;
            this.propertyTypes[0].selected = false;
          }
        });
      }
      if (params[SearchParams.statuses]) {
        let statuses = params[SearchParams.statuses];
        this.status.map((item) => {
          if (statuses.indexOf(item.value) >= 0) {
            item.selected = true;
            this.status[0].selected = false;
          }
        });
      }

      if (params[SearchParams.minSqFt]) {
        this.minFootage = this.minFootages.filter((item) => item.value == params[SearchParams.minSqFt])[0];
      }
      if (params[SearchParams.maxSqFt]) {
        this.maxFootage = this.maxFootages.filter((item) => item.value == params[SearchParams.maxSqFt])[0];
      }
      if (params[SearchParams.minLotSize]) {
        this.lotSize = this.lotSizes.filter((item) => item.value == params[SearchParams.minLotSize])[0];
      }
      if (params[SearchParams.minYearBuilt]) {
        this.minYear = params[SearchParams.minYearBuilt];
      }
      if (params[SearchParams.maxYearBuilt]) {
        this.maxYear = params[SearchParams.maxYearBuilt];
      }
    });
  }

  updateParams() {
    const params = {
      ...this.currentParams,
      ...this.params,
    };
    this.closeEvent.emit(true);
    this.urlParserService.setParams(params);
  }

  updatePropertyTypes(idx: number) {
    if (idx == 0) {
      if (this.propertyTypes[idx].selected) {
        let i = 1;
        for (i = 1; i < this.propertyTypes.length; i++) {
          this.propertyTypes[i].selected = false;
        }
      }
      this.params = {
        ...this.params,
        [SearchParams.propertyTypes]: null,
      };
      // this.urlParserService.removeParam(SearchParams.propertyTypes, this.url);
    } else {
      if (this.propertyTypes[idx].selected) {
        this.propertyTypes[0].selected = false;
      }
      let selectedPropertyTypes = [];
      for (let i = 1; i < this.propertyTypes.length; i++) {
        if (this.propertyTypes[i].selected) {
          selectedPropertyTypes.push(this.propertyTypes[i].value);
        }
      }
      if (selectedPropertyTypes.length > 0) {
        // this.urlParserService.addParam(SearchParams.propertyTypes, selectedPropertyTypes.join(','), this.url);
        this.params = {
          ...this.params,
          [SearchParams.propertyTypes]: selectedPropertyTypes.join(','),
        };
      } else {
        // this.propertyTypes[0].selected = false;
        // this.urlParserService.removeParam(SearchParams.propertyTypes, this.url);
        this.params = {
          ...this.params,
          [SearchParams.propertyTypes]: null,
        };
      }
    }

    this.checkAppliedFilters();
  }

  updateStatus(idx: number) {
    if (idx == 0) {
      if (this.status[idx].selected) {
        let i = 1;
        for (i = 1; i < this.status.length; i++) {
          this.status[i].selected = false;
        }
        // this.urlParserService.removeParam(SearchParams.statuses, this.url);
        this.params = {
          ...this.params,
          [SearchParams.statuses]: null,
        };
      }
    } else {
      if (this.status[idx].selected) {
        this.status[0].selected = false;
      }
      let selectedStatuses = [];
      for (let i = 1; i < this.status.length; i++) {
        if (this.status[i].selected) {
          selectedStatuses.push(this.status[i].value);
        }
      }
      if (selectedStatuses.length > 0) {
        // this.urlParserService.addParam(SearchParams.statuses, selectedStatuses.join(','), this.url);
        this.params = {
          ...this.params,
          [SearchParams.statuses]: selectedStatuses.join(','),
        };
      } else {
        // this.status[0].selected = true;
        // this.urlParserService.removeParam(SearchParams.statuses, this.url);
        this.params = {
          ...this.params,
          [SearchParams.statuses]: null,
        };
      }
    }

    this.checkAppliedFilters();
  }

  updateMinSquareFootage(item: DropdownItem) {
    this.minFootage = item;
    if (this.minFootage.label == 'No Min') {
      // this.urlParserService.removeParam(SearchParams.minSqFt, this.url);
      this.params = {
        ...this.params,
        [SearchParams.minSqFt]: null,
      };
    } else {
      // this.urlParserService.addParam(SearchParams.minSqFt, this.minFootage.value, this.url);
      this.params = {
        ...this.params,
        [SearchParams.minSqFt]: this.minFootage.value,
      };
    }
    this.checkAppliedFilters();
  }

  updateMaxSquareFootage(item: DropdownItem) {
    this.maxFootage = item;
    if (this.maxFootage.label == 'No Max') {
      // this.urlParserService.removeParam(SearchParams.maxSqFt, this.url);
      this.params = {
        ...this.params,
        [SearchParams.maxSqFt]: null,
      };
    } else {
      // this.urlParserService.addParam(SearchParams.maxSqFt, this.maxFootage.value, this.url);
      this.params = {
        ...this.params,
        [SearchParams.maxSqFt]: this.maxFootage.value,
      };
    }
    this.checkAppliedFilters();
  }

  updateLotSize(item: DropdownItem) {
    this.lotSize = item;
    if (this.lotSize.label == 'Any') {
      // this.urlParserService.removeParam(SearchParams.minLotSize, this.url);
      this.params = {
        ...this.params,
        [SearchParams.minLotSize]: null,
      };
    } else {
      // this.urlParserService.addParam(SearchParams.minLotSize, this.lotSize.value, this.url);
      this.params = {
        ...this.params,
        [SearchParams.minLotSize]: this.lotSize.value,
      };
    }
    this.checkAppliedFilters();
  }

  changeMinYear() {
    const year = parseInt(String(this.minYear));
    if (year < 1900) this.minYear = '1900';
    if (year > this.thisYear) this.minYear = `${this.thisYear}`;

    if (this.minYear) {
      // this.urlParserService.addParam(SearchParams.minYearBuilt, this.minYear, this.url);
      this.params = {
        ...this.params,
        [SearchParams.minYearBuilt]: this.minYear,
      };
    } else {
      // this.urlParserService.removeParam(SearchParams.minYearBuilt, this.url);
      this.params = {
        ...this.params,
        [SearchParams.minYearBuilt]: null,
      };
    }
  }

  changeMaxYear() {
    const year = parseInt(String(this.maxYear));
    if (year < 1900) this.maxYear = '1900';
    if (year > this.thisYear) this.maxYear = `${this.thisYear}`;

    if (this.maxYear) {
      // this.urlParserService.addParam(SearchParams.maxYearBuilt, this.maxYear, this.url);
      this.params = {
        ...this.params,
        [SearchParams.maxYearBuilt]: this.maxYear,
      };
    } else {
      // this.urlParserService.removeParam(SearchParams.maxYearBuilt, this.url);
      this.params = {
        ...this.params,
        [SearchParams.maxYearBuilt]: null,
      };
    }
  }

  checkAppliedFilters() {
    this.filterOptions = 0;
    this.propertyTypes.forEach((it) => {
      if (it.label != 'Any' && it.selected) {
        this.filterOptions++;
      }
    });
    if (this.minFootage.label != 'No Min' || this.maxFootage.label != 'No Max') {
      this.filterOptions++;
    }
    if (this.minYear != 'No Min' || this.maxYear != 'No Max') {
      this.filterOptions++;
    }
    if (this.lotSize.value != 0) {
      this.filterOptions++;
    }

    this.status.forEach((it) => {
      if (it.label != 'Show all' && it.selected) {
        this.filterOptions++;
      }
    });
    this.isEnableApply = false;
    if (this.filterOptions > 0) {
      this.isEnableApply = true;
    } else {
      if (this.propertyTypes[0].selected && this.status[0].selected) {
        this.isEnableApply = true;
      }
    }
  }

  closeModal() {
    this.closeEvent.emit();
  }

  applyFilters() {
    this.applyFiltersEvent.emit(this.filterOptions);
    this.closeModal();
  }

  reset() {
    this.filterOptions = 0;

    this.propertyTypes = [
      { label: 'Any', value: '', selected: true },
      { label: 'Single Family Home', value: 'single', selected: false },
      { label: 'Townhome', value: 'townhouse', selected: false },
      { label: 'Duplex', value: 'duplex', selected: false },
      { label: 'Triplex', value: 'triplex', selected: false },
      { label: 'Ranch', value: 'ranch', selected: false },
      { label: 'Multi-family Home', value: 'multi', selected: false },
      { label: 'Mobile Home', value: 'mobile', selected: false },
      { label: 'Lots/Land', value: 'land', selected: false },
      { label: 'Commercial', value: 'commercial', selected: false },
    ];

    this.status = [
      { label: 'Show all', value: '', selected: true },
      { label: 'Active Listing', value: 'active', selected: false },
      { label: 'Pending', value: 'pending', selected: false },
    ];

    this.minPrice = { label: 'No Min', value: 0 };
    this.maxPrice = { label: 'No Max', value: 0 };
    this.minFootage = { label: 'No Min', value: 0 };
    this.maxFootage = { label: 'No Max', value: 0 };
    this.lotSize = { label: 'Any', value: 0 };

    this.minYear = 'No Min';
    this.maxYear = 'No Max';

    this.urlParserService.addParams({
      [SearchParams.propertyTypes]: null,
      [SearchParams.statuses]: null,
      [SearchParams.minSqFt]: null,
      [SearchParams.maxSqFt]: null,
      [SearchParams.minLotSize]: null,
      [SearchParams.maxLotSize]: null,
      [SearchParams.minYearBuilt]: null,
      [SearchParams.maxYearBuilt]: null,
    });

    this.checkAppliedFilters();
  }

  yearBuiltChangeEventHandler(event: any) {
    if (this.minYear && (parseInt(String(this.minYear)) < 1900 || parseInt(String(this.minYear)) > this.thisYear)) {
      this.changeValid.emit(false);
      return;
    }
    if (this.maxYear && (parseInt(String(this.maxYear)) < 1900 || parseInt(String(this.maxYear)) > this.thisYear)) {
      this.changeValid.emit(false);
      return;
    }
    this.changeValid.emit(true);
  }
}
