import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'hch-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() currentPage: number = 1;
  @Input() totalItems = 0;
  @Input() maxSize = 5;
  @Input() itemsPerPage = 20;
  @Input() displayNumbers = true;

  @Output() change = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  changeEvent($event: any) {
    this.change.emit($event);
  }
}
