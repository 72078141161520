<!-- <form [formGroup]="inputForm"> -->
<form>
  <div
    dropdown
    #dropdown="bs-dropdown"
    class="hch-floating-dropdown"
    [ngStyle]="styles"
    [isDisabled]="disabled"
    [style.opacity]="disabled ? '0.5' : '1'"
  >
    <span *ngIf="prefix" class="prefix">{{ prefix }}</span>
    <input
      type="text"
      class="form-control"
      numberSeparator
      #inp
      [formControl]="input"
      [id]="'dropdown-' + category"
      [matAutocomplete]="auto"
      [name]="'dropdown-' + category"
      [placeholder]="placeholder"
      [ngClass]="{ prefix: !!prefix, suffix: !!suffix }"
      (focusout)="selectItem(inp.value, 'focus')"
    />
    <span *ngIf="suffix" class="suffix">{{ suffix }}</span>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option
        *ngFor="let option of items$ | async"
        [value]="option.value ? (option.value | number : '1.0-0') : ''"
        (click)="selectItem(option.value, 'click')"
      >
        {{ option.label }}
      </mat-option>
    </mat-autocomplete>
  </div>
</form>
