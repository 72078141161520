<div style="margin-top: 2rem">
  <div class="mb-2">
    <label for="state-selector" [style.color]="labelColor">
      To view home search please select your current state of residence
    </label>
    <div class="pt-1 pb-1" *ngIf="notice">
      <b>
        Due to state regulations we are not able to display homes for sale to you at this time. We apologize for the
        inconvenience.
      </b>
    </div>
    <br />
    <select
      name="state-selector"
      id="state-selector"
      class="browser-default custom-select pt-2"
      [ngModel]="selectedState"
      (ngModelChange)="updateSelectedState($event)"
    >
      <option *ngFor="let state of options" [ngValue]="state">{{ state.text }}</option>
    </select>
  </div>
</div>
