import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { catchError, finalize, of, map, throwError } from 'rxjs';

import { AuthException } from 'src/app/core/enums/auth.enum';
import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';
import { AuthService } from 'src/app/core/services/auth.service';
import { FormValidationService } from 'src/app/core/services/form-validation.service';

@Component({
  selector: 'hch-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent extends Unsubscriber implements OnInit {
  form: FormGroup;
  // strength = 0;
  indicationStyle = {};
  strengthLegend = ['Very Weak', 'Good', 'Strong', 'Best'];
  changedPassword = false;
  confirmingPassword = false;
  submitting = false;
  errorMessage = '';

  showPasswords = false;

  constructor(
    private authService: AuthService,
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    public formValidationService: FormValidationService
  ) {
    super();

    this.form = this.fb.group({
      newPassword: ['', Validators.compose([Validators.required, this.formValidationService.passwordValidator()])],
      currentPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.addSubscription = this.formValidationService.passwordStrength.subscribe((strength) => {
      this.indicationStyle = {
        weak: strength === 0,
        good: strength === 1,
        strong: strength === 2,
        best: strength === 3,
      };
      this.confirmingPassword = strength == 3;
    });
  }

  get passwordMustMatchError() {
    if (this.confirmPassword?.value.length > 0 && this.confirmPassword?.value !== this.newPassword?.value) {
      return 'Passwords must match';
    }
    return '';
  }
  get newPassword() {
    return this.form.get('newPassword');
  }
  get currentPassword() {
    return this.form.get('currentPassword');
  }
  get confirmPassword() {
    return this.form.get('confirmPassword');
  }

  confirm() {
    this.submitting = true;
    this.addSubscription = this.authService
      .changePassword(this.currentPassword!.value, this.newPassword!.value)
      .pipe(
        map(() => {
          this.changedPassword = true;
        }),
        catchError((error) => {
          if (error && error.msg == AuthException.WRONG_PASSWORD) {
            this.errorMessage = 'Invalid password';
          } else this.errorMessage = 'Unexpected error';
          return throwError(() => error);
        }),
        finalize(() => {
          this.submitting = false;
        })
      )
      .subscribe();
  }
}
