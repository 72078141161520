<div
  class="hch-input"
  [ngStyle]="styles"
  [ngClass]="dirty && (errorRequired || errorInvalid || errorMessage) ? 'has-error' : ''"
>
  <input
    class="text-s"
    [id]="'input-' + field"
    [name]="'input-' + field"
    placeholder=" "
    [attr.aria-label]="placeholder"
    [attr.maxlength]="maxlength"
    [ngStyle]="{ background: background ? background : 'white' }"
    [readonly]="readonly"
    [required]="required"
    [mask]="mask"
    [prefix]="prefix"
    [suffix]="suffix"
    [dropSpecialCharacters]="dropSpecialCharacters"
    [thousandSeparator]="thousandSeparator"
    [type]="type"
    [ngModel]="value"
    (ngModelChange)="changeValue($event)"
    (blur)="onBlur($event)"
    (keyup.enter)="onKeyupEnter($event)"
  />
  <label
    [for]="'input-' + field"
    class="placeholder text-s"
    [ngStyle]="{ background: background ? background : 'white' }"
  >
    {{ placeholder }}
  </label>
</div>
<div class="error" *ngIf="dirty && errorRequired">
  <img class="alert-img" src="assets/icons/alert.svg" alt="" />
  <span class="text-xs error-text" *ngIf="errorRequired">
    {{ selectedField.required }}
  </span>
</div>
<div class="error" *ngIf="dirty && errorInvalid">
  <img class="alert-img" src="assets/icons/alert.svg" alt="" />
  <span class="text-xs error-text" *ngIf="errorInvalid">
    {{ selectedField.invalid }}
  </span>
</div>
<div class="error" *ngIf="dirty && errorMessage">
  <img class="alert-img" src="assets/icons/alert.svg" alt="" />
  <span class="text-xs error-text" *ngIf="errorMessage">
    {{ errorMessage }}
  </span>
</div>
