import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BuyService } from 'src/app/buy/buy.service';

@Component({
  selector: 'hch-mobile-filters',
  templateUrl: './mobile-filters.component.html',
  styleUrls: ['./mobile-filters.component.scss'],
})
export class MobileFiltersComponent implements OnInit {
  valid = true;

  constructor(public bsModalRef: BsModalRef, public buyService: BuyService) {}

  ngOnInit(): void {}
}
