<div dropdown #savedSearchDropdown="bs-dropdown" class="position-relative hch-save-search-dropdown" placement="top">
  <button
    class="btn btn-outline-primary btn-md dropdown-toggle save-search-btn"
    (click)="toggleDropdown()"
    [disabled]="disabledSearchButton || savedSearchDropdown.isOpen || totalElements == 0"
    [attr.aria-expanded]="savedSearchDropdown.isOpen"
  >
    Save Search
  </button>

  <div *dropdownMenu cdkTrapFocus class="dropdown-menu dropdown-menu-left save-search">
    <button autofocused class="close-btn" (click)="closeDropdown()" aria-label="Close Saved Search Modal">
      <img src="assets/icons/close.svg" alt="Close Saved Search Modal" />
    </button>
    <div class="dropdown-body auth-form" *ngIf="route.queryParams | async as params">
      <h5>Save Your Search!</h5>
      <div class="form-group">
        <input
          id="name-your-search"
          #input
          type="text"
          class="text-s"
          placeholder="Name Your Search"
          [ngModel]="params['city'] + ' ' + params['state']"
        />
        <label for="name-your-search" class="placeholder text-s">Name Your Search</label>
      </div>
      <div class="desc">
        <div>
          Price:
          {{
            !params['minPrice'] || params['minPrice'] == 'No Min'
              ? 'No Min'
              : (params['minPrice'] | currency : 'USD' : 'symbol' : '1.0-0')
          }}
          -
          {{
            !params['maxPrice'] || params['maxPrice'] == 'No Max'
              ? 'No Max'
              : (params['maxPrice'] | currency : 'USD' : 'symbol' : '1.0-0')
          }}
        </div>
        <div *ngIf="params['propertyTypes']">
          <span>Property types:</span>
          <span *ngFor="let item of params['propertyTypes'].split(',')">{{ item }}</span>
        </div>
        <div>
          <span>{{ params['minBedrooms'] > 0 ? params['minBedrooms'] + '+' : 'Any' }} beds,</span>
          <span>{{ params['minBathrooms'] > 0 ? params['minBathrooms'] + '+' : 'Any' }} baths</span>
        </div>
        <div *ngIf="params['minSqFt'] || params['maxSqFt']">
          <span>Sqft:</span>
          <span>{{ params['minSqFt'] ? params['minSqFt'] : 'No min' }}</span>
          <span>-</span>
          <span>{{ params['maxSqFt'] ? params['maxSqFt'] : 'No max' }}</span>
        </div>
        <div *ngIf="params['minLotSize']">
          <span>Lot size min: {{ params['minLotSize'] }} {{ params['minLotSize'] > 7050 ? 'acres' : 'sqft.' }}</span>
        </div>
        <div *ngIf="params['minYearBuilt'] || params['maxYearBuilt']">
          <span>Year Built:</span>
          <span>{{ params['minYearBuilt'] ? params['minYearBuilt'] : 'No min' }}</span>
          <span>-</span>
          <span>{{ params['maxYearBuilt'] ? params['maxYearBuilt'] : 'No max' }}</span>
        </div>
      </div>
      <button
        class="btn btn-primary w-100 submit"
        [disabled]="!!!input.value"
        (click)="saveSearch(params, input.value)"
      >
        Save
      </button>
    </div>
  </div>
</div>
