import { Component, OnInit } from '@angular/core';
import { MediaService } from 'src/app/core/services/media.service';
import { RoutingService } from 'src/app/core/services/routing.service';

@Component({
  selector: 'hch-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  constructor(public mediaService: MediaService, public routingService: RoutingService) {}

  ngOnInit(): void {}
}
