import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hch-hamburger-menu',
  templateUrl: './hamburger-menu.component.html',
  styleUrls: ['./hamburger-menu.component.scss'],
})
export class HamburgerMenuComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
