import { Component, Input, OnInit, forwardRef, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, combineLatest, map, Observable, of, startWith, switchMap } from 'rxjs';

import { DropdownItem } from 'src/app/core/models/dropdown.model';
import { DropdownOptionsE } from 'src/app/core/models/dropdown.model';

@Component({
  selector: 'hch-editable-floating-dropdown',
  templateUrl: './editable-floating-dropdown.component.html',
  styleUrls: ['./editable-floating-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditableFloatingDropdownComponent),
      multi: true,
    },
  ],
})
export class EditableFloatingDropdownComponent implements OnInit, ControlValueAccessor {
  @Output() changes = new EventEmitter<any>();
  @Output() isOpen = new EventEmitter<boolean>();
  @Output() tabPress = new EventEmitter<boolean>();
  @Output() shifttabPress = new EventEmitter<boolean>();

  private _value: DropdownItem = {
    label: '',
    value: '',
  };
  get value(): DropdownItem {
    return this._value;
  }
  @Input()
  set value(val: DropdownItem) {
    this._value = val;
    this.input.patchValue(this.formatNumber(val.label));
  }

  items$ = new BehaviorSubject<DropdownItem[]>([]);
  private _items: DropdownItem[] = [];
  get items(): DropdownItem[] {
    return this._items;
  }
  @Input()
  set items(val: DropdownItem[]) {
    this.items$.next(val);
    this._items = val;
    // this.changeDetector.detectChanges();
  }

  @Input() category: keyof typeof DropdownOptionsE = 'TIMEFRAME';
  @Input() disabled: boolean = false;
  @Input() icon: string = ''; // icon url
  // @Input() items: DropdownItem[] = [];
  @Input() placeholder: string = '';
  @Input() styles: any;

  @Input() mask: any = '';
  @Input() prefix: string = '';
  @Input() suffix: string = '';
  @Input() thousandSeparator: string = '';

  input = new FormControl('');

  // filteredItems = new Observable<DropdownItem[]>();

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor() {}

  formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  formatNumber(value: string) {
    const onlyNumbers = value.replace(/[^0-9]*/g, '');
    const num = Number(onlyNumbers);
    if (isNaN(num)) return value;
    return this.formatter.format(num);
  }

  ngOnInit() {
    // this.filteredItems = this.items$;
    // combineLatest([this.input.valueChanges.pipe(startWith('')), this.items$]).pipe(
    //   switchMap(([value]) => {
    //     if (value) return of(this.formatNumber(value));
    //     return of('');
    //   }),
    //   map((value) => {
    //     return this._filter(value);
    //   })
    // );
    // this.filteredItems = this.input.valueChanges.pipe(
    //   startWith(''),
    //   map((value) => {
    //     if (value) this.formatNumber(value);
    //     return '';
    //   }),
    //   map((value) => this._filter(value))
    // );
  }

  // private _filter(value: string | number | null) {
  //   const items = this.items$.getValue();
  //   if (!value) return items;
  //   const newItems = items.filter((item) => {
  //     return typeof item.label == 'string' && item.value.includes(String(value));
  //   });
  //   return newItems;
  // }

  selectItem(value: string | number | null, event: string) {
    if (event == 'focus' && (!value || value === 0 || value === '0')) {
      return;
    }
    this.changes.emit(value);
    this.onChange(value);
    this.onTouched(value);
  }

  writeValue(value: any): void {
    this._value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
