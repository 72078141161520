import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

/**
 * AutoFocus focuses the element with the autofocused directive after the view is initialized.
 *
 * @example
 * <button autofocused></button>
 *
 * <button [autofocused]="focusButton"></button>
 */
@Directive({
  selector: '[autofocused]',
})
export class AutoFocus implements AfterViewInit {
  @Input() autofocused?: boolean | '';

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    if (this.autofocused || this.autofocused === '') {
      setTimeout(() => {
        this.elementRef.nativeElement.focus();
      }, 0);
    }
  }
}
