import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

/**
 * Automatically unsubscribes from all added subscriptions when the component is destroyed.
 *
 * @example
 * // Extend any Angular component with this:
 * export class SampleComponent extends Unsubscriber
 *
 * // Call the super() in the component's constructor to enable the class.
 * constructor() { super() }
 *
 * // Add a subscription
 * this.addSubscription = observable$.subscribe();
 *
 * // Add an observable that is automatically subscribed to
 * this.addObservable = observable$;
 */
@Injectable({
  providedIn: 'root',
})
export class Unsubscriber implements OnDestroy {
  constructor() {}

  private _subscription: Subscription = new Subscription();

  ngOnDestroy() {
    if (this._subscription) this._subscription.unsubscribe();
  }

  protected resetSubscription() {
    if (this._subscription) this._subscription.unsubscribe();
  }

  /**
   * Adds a subscription. When the component is destroyed, any Subscriptions are also unsubscribed.
   *
   * @example
   * this.addSubscription = observable$.subscribe()
   */
  set addSubscription(sub: Subscription | undefined) {
    if (sub) this._subscription.add(sub);
  }
}
