import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownItem } from 'src/app/core/models/dropdown.model';

@Component({
  selector: 'hch-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit {
  @Input() options: DropdownItem[] = [];
  @Input() selectedOption: DropdownItem | undefined;
  @Output() changeEvent = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  getSelectedIndex() {
    for (let index = 0; index < this.options.length; index += 1) {
      if (this.options[index].value === this.selectedOption?.value) {
        return index;
      }
    }
    return -1;
  }

  increase() {
    if (!this.selectedOption) {
      this.selectedOption = this.options.length > 0 ? this.options[0] : undefined;
      return;
    }

    const index = this.getSelectedIndex();
    if (index !== -1) {
      if (index === this.options.length - 1) {
        return;
      }
      this.selectedOption = this.options[index + 1];
      this.changeEvent.emit(this.selectedOption);
    } else {
      this.selectedOption = undefined;
    }
  }

  decrease() {
    if (!this.selectedOption) {
      return;
    }

    const index = this.getSelectedIndex();
    if (index !== -1) {
      if (index === 0) {
        this.selectedOption = undefined;
        return;
      }
      this.selectedOption = this.options[index - 1];
      this.changeEvent.emit(this.selectedOption);
    } else {
      this.selectedOption = undefined;
    }
  }
}
