import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currency2',
})
export class Currency2Pipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): string | null {
    if (value) return currency2(value);
    return null;
  }
}

@Pipe({
  name: 'currency3',
})
export class Currency3Pipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): string | null {
    if (value) return currency3(value as number | string);
    return null;
  }
}

export function currency2(value: unknown): string {
  let price = Number(value);
  if (isNaN(price)) return 'N/A';
  let suffix = 'k';
  if (price >= 1000000) suffix = 'M';
  while (price >= 1000) price /= 1000;
  price = Math.round(price * 10) / 10;
  return '$' + price.toLocaleString('en-US') + suffix;
}

export function currency3(value: number | string): string {
  let price = value;
  if (typeof price == 'string' && (price.includes('No Min') || price.includes('No Max'))) {
    return price;
  }
  if (typeof price == 'string') {
    price = Number(price.replace(/[^0-9]/g, ''));
  }
  if (typeof price == 'number') {
    let suffix = 'k';
    if (price >= 1000000) suffix = 'M';
    while (price >= 1000) price /= 1000;
    price = Math.round(price * 10) / 10;
    return '$' + price.toLocaleString('en-US') + suffix;
  }
  return '';
}
