import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { Listing, Provider, ProviderE } from '../models/listing.model';
import { UserRequest, UserRequestMR, UserRequestsE, UserActivity, UserSearch } from '../models/user.model';
import { AuthService } from './auth.service';
import { ModalService } from './modal.service';

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  constructor(private http: HttpClient, private authService: AuthService, private modalService: ModalService) {}

  getFavoriteIds(): string[] {
    const favorites = this.getFavorites();
    if (favorites) {
      return favorites.map((fav) => fav.id);
    }
    return [];
  }

  getFavorites() {
    if (this.authService.currentUserValue) {
      const favorites = this.authService.currentUserValue.favorites;
      if (favorites) return favorites;
    }
    return [];
  }

  isFavoritedItem(id: string) {
    if (!this.authService.currentUserValue) {
      return false;
    }
    let items = this.getFavoriteIds();
    if (!items || items.length == 0) {
      return false;
    }
    if (items.indexOf(id) >= 0) {
      return true;
    }
    return false;
  }

  /**
   * Toggles an item as favorited or not.
   */
  toggleFavoriteItem(id: string, provider: Provider, listing: Listing) {
    let items = this.getFavorites();
    let ids = this.getFavoriteIds();
    let pos = ids.indexOf(id);
    let newFavorite = false;

    if (pos >= 0) {
      items.splice(pos, 1); // remove the favorite
    } else {
      items.push({ id, provider }); // add the favorite
      newFavorite = true;
      // Format url for backend
      const url = `${location.origin}/full-listing/${id}/${
        provider === ProviderE.LISTHUB ? '001' : provider === ProviderE.REALSTAQ ? '002' : ''
      }`;
      // TODO
      // this.logFavorite(listing, provider, url).subscribe();
    }

    return this.http
      .post(environment.HUBAPI_BASE_URL + '/activity/update-favorites', {
        favorites: items,
        listing,
        newFavorite,
        listingId: id,
        provider,
      })
      .pipe(
        map((data: any) => {
          if (data) {
            this.authService.updateLocalUserInfo(data.user);
            return newFavorite;
          }
          return;
        })
      );
    // return this.http.post(environment.AUTHAPI_BASE_URL + '/update-favorites', { favorites: items }).pipe(
    //   map((data: any) => {
    //     if (data) {
    //       this.authService.updateLocalUserInfo(data.user);
    //       return saved;
    //     }
    //     return;
    //   })
    // );
  }

  /**
   * Logs the user's new favorite.
   */
  // private logFavorite(listing: Listing, provider: string, url: string) {
  //   return this.http.post(
  //     environment.HUBAPI_BASE_URL + '/activity/log-favorite',
  //     { listing: { ...listing, provider, url } },
  //     { observe: 'response' }
  //   );
  // }

  /**
   * Logs the users first search and saves it in the database if null.
   *
   * Updates local user object with updated field.
   */
  logFirstSearch(search: any) {
    return this.http.post(environment.HUBAPI_BASE_URL + '/activity/log-first-search', { search }).pipe(
      map((data: any) => {
        if (data) {
          this.authService.updateLocalUserInfo(data.user);
          return data.user;
        }
      })
    );
  }

  /**
   * Logs the user's saved searches.
   * @param search
   */
  logSavedSearch(search: UserSearch) {
    return this.http
      .post(
        environment.HUBAPI_BASE_URL + '/activity/log-saved-search',
        { search }
        // { observe: 'response' }
      )
      .pipe(
        map((data: any) => {
          if (data) {
            this.authService.updateLocalUserInfo(data.user);
            return data.user;
          }
        })
      );
  }

  /**
   * Logs the user's home valuation request.
   * Also, emails the user with the report.
   */
  logHomeValuationRequest(activity: UserActivity) {
    return this.http.post(environment.HUBAPI_BASE_URL + '/activity/log-home-valuation-request', { activity });
  }

  /**
   * Logs the user's CMA request.
   */
  logCMARequest(activity: UserActivity) {
    return this.http.post(environment.HUBAPI_BASE_URL + '/activity/log-cma-request', { activity });
  }

  /**
   * Logs the user's request to tour the house.
   */
  logRequestShowing(listing: UserActivity) {
    return this.http.post(environment.HUBAPI_BASE_URL + '/activity/log-request-showing', { listing });
  }

  /**
   * Sends user's request to API from Get Info, Contact Me, Need a Real Estate Agent? forms.
   * @param {UserRequestsE} requestType Conforms to the enum UserRequests in user-activity.model
   * @param {UserRequest} request The request object
   * @returns Contains { success: true } if a new activity record was created in HCH database and in Salesforce.
   */
  sendRequest(requestType: UserRequestsE, request: UserRequest) {
    return this.http.post(environment.HUBAPI_BASE_URL + '/cta/' + requestType, { request }, { observe: 'response' });
  }

  sendRequestMR(requestType: UserRequestsE, request: UserRequestMR) {
    return this.http.post(environment.HUBAPI_BASE_URL + '/cta/' + requestType, { request }, { observe: 'response' });
  }

  /**
   * Displays the confirmation modal with either a success or error message.
   * @param hasError Pass true if an error is caught when posting to this.activityService.sendRequest
   */
  displayConfirmationModal(hasError: boolean) {
    return this.modalService.showConfirmationModal(hasError);
  }

  /**
   * Updates the user's saved searches based on City, State.
   */
  updateSearches(searches: UserSearch[]) {
    return this.http.post(environment.AUTHAPI_BASE_URL + '/update-searches', { searches }).pipe(
      map((data: any) => {
        if (data) {
          this.authService.updateLocalUserInfo(data.user);
          return data.user;
        }
      })
    );
  }
}
