<div [ngClass]="{ 'display-numbers': !displayNumbers }">
  <pagination
    [totalItems]="totalItems"
    [ngModel]="currentPage"
    [customNextTemplate]="nextTemplate"
    [customPreviousTemplate]="prevTemplate"
    [itemsPerPage]="itemsPerPage"
    [maxSize]="maxSize"
    (pageChanged)="changeEvent($event)"
  ></pagination>
  <ng-template #prevTemplate>Prev</ng-template>
  <ng-template #nextTemplate>Next</ng-template>
</div>
