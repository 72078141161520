<div class="card calculator" id="listing-calculator">
  <div>
    <h4 class="sub-title">Payment Calculator</h4>
    <div class="calculator-body">
      <div class="price">
        <img *ngIf="type != 'full'" src="assets/icons/pie-chart-big.svg" alt="pie-chart" />
        <h5>${{ (monthlyPayment | tonumber : 'ceil').toLocaleString('en-US') }}/Mo</h5>
        <span>est. payment</span>
      </div>
      <div class="calc-container">
        <div class="calc-group">
          <div class="calc-item form-group">
            <label class="calc-type" for="price">Down Payment</label>
            <div style="display: flex">
              <input
                class="price1 first"
                id="price"
                mask="separator.0"
                prefix="$"
                thousandSeparator=","
                [dropSpecialCharacters]="true"
                [(ngModel)]="downPrice"
                (keyup)="changeDownPrice()"
              />
              <input
                class="percent1 last"
                mask="separator.2"
                suffix="%"
                [dropSpecialCharacters]="true"
                [(ngModel)]="downRate"
                (keyup)="changeDownRate()"
              />
            </div>
          </div>
          <div class="calc-item form-group">
            <label class="calc-type" for="interest">Interest Rate</label>
            <input
              class="percent2"
              id="interest"
              mask="separator.2"
              suffix="%"
              [dropSpecialCharacters]="true"
              [(ngModel)]="interestRate"
              (keyup)="changeInterestRate()"
            />
          </div>
        </div>
        <div class="calc-group">
          <div class="calc-item loan" style="align-self: flex-end">
            <!-- <label class="calc-type">Loan Type</label> -->
            <hch-floating-dropdown
              category="LOAN_TYPE"
              placeholder="Loan Type"
              [items]="loanTypes"
              [styles]="{ 'min-width': '10rem' }"
              (change)="changeLoan($event)"
            ></hch-floating-dropdown>
          </div>
          <div class="calc-item form-group" style="align-self: flex-end">
            <label class="calc-type" for="home-price">Home Price</label>
            <input
              class="price2"
              id="home-price"
              mask="separator.0"
              prefix="$"
              thousandSeparator=","
              [ngModel]="presentPrice"
              readonly
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div *ngIf="type == 'full'">
    <canvas baseChart [data]="chartData" [options]="chartOptions" type="doughnut"></canvas>
  </div> -->
</div>
