<div class="card" style="padding: 1rem; margin: 1rem 0">
  <div style="margin-bottom: 0.5rem">
    <h3 style="font-size: 1.25rem; margin-bottom: 1rem" id="get-more-info">Get More Information</h3>
    <p style="font-size: 0.8rem; margin-bottom: 0.2rem">
      We respect your privacy. Your information WILL NOT BE SHARED, SOLD, or RENTED to anyone, for any reason outside
      the course of normal real estate exchange.
    </p>
    <p style="font-size: 0.8rem">
      By submitting, you agree to our
      <a (click)="modalService.showTermsConditionsModal()" class="link">Terms of Use</a>
      and
      <a (click)="modalService.showPrivacyPolicyModal()" class="link">Privacy Policy.</a>
    </p>
  </div>
  <form class="auth-form" [formGroup]="form">
    <div class="row">
      <div class="col-12 col-sm-6">
        <hch-input
          placeholder="First Name"
          formControlName="firstName"
          field="firstName"
          [dirty]="firstName?.dirty"
          [errorInvalid]="firstName?.errors?.['invalidName']"
          [errorRequired]="firstName?.errors?.['required']"
        ></hch-input>
        <hch-input
          placeholder="Last Name"
          formControlName="lastName"
          field="lastName"
          [dirty]="lastName?.dirty"
          [errorInvalid]="lastName?.errors?.['invalidName']"
          [errorRequired]="lastName?.errors?.['required']"
        ></hch-input>
        <hch-input
          placeholder="Email"
          formControlName="email"
          field="email"
          [dirty]="email?.dirty"
          [errorInvalid]="email?.errors?.['invalidEmail']"
          [errorRequired]="email?.errors?.['required']"
        ></hch-input>
        <hch-input
          placeholder="Phone Number"
          formControlName="phone"
          field="phone"
          mask="(000) 000-0000"
          [dirty]="phone?.dirty"
          [errorInvalid]="phone?.errors?.['invalidPhoneNumber']"
          [errorRequired]="phone?.errors?.['required']"
        ></hch-input>
      </div>
      <div class="col-12 col-sm-6">
        <div class="form-group">
          <textarea
            class="text-s"
            [ngClass]="{ 'error-field': (submitting || message?.touched) && message?.errors?.['required'] }"
            style="min-height: 200px"
            placeholder="Enter message"
            formControlName="message"
          ></textarea>
          <label class="placeholder text-s">Enter Message</label>
          <div class="error text-xs" *ngIf="(submitting || message?.touched) && message?.errors?.['required']">
            <img class="alert-img" src="assets/icons/alert.svg" alt="" />
            <span class="text-xs error-text">Message is required</span>
          </div>
          <div class="error text-xs" *ngIf="errorMessage && !message?.errors?.['required']">
            <img class="alert-img" src="assets/icons/alert.svg" alt="" />
            <span class="text-xs error-text">{{ errorMessage }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn btn-primary send-btn submit-btn" [disabled]="form.invalid || submitting" (click)="submit()">
        {{ submitting ? 'Submitting . . .' : 'Submit' }}
      </button>
    </div>
  </form>
</div>
