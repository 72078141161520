import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { catchError, finalize, of } from 'rxjs';

import { AuthException } from 'src/app/core/enums/auth.enum';
import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';
import { AuthService } from 'src/app/core/services/auth.service';
import { FormValidationService } from 'src/app/core/services/form-validation.service';

@Component({
  selector: 'hch-create-new-password',
  templateUrl: './create-new-password.component.html',
  styleUrls: ['./create-new-password.component.scss'],
})
export class CreateNewPasswordComponent extends Unsubscriber implements OnInit {
  @Output() closeEvent = new EventEmitter();
  @Output() changeCategory = new EventEmitter();
  @Input() username = '';

  authErrMsg = '';
  passwordForm: FormGroup;
  bShowNewPassword = false;
  bShowConfirmPassword = false;
  indicationStyle = {};

  strength = 0;
  strengthLegend = ['Very Weak', 'Good', 'Strong', 'Best'];
  step = 0;
  submitted = false;
  token: string = '';

  constructor(
    private authService: AuthService,
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private router: Router,
    private formValidationService: FormValidationService
  ) {
    super();
    this.passwordForm = this.fb.group({
      newPassword: ['', Validators.compose([Validators.required, this.formValidationService.passwordValidator()])],
      confirmPassword: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.addSubscription = this.passwordForm.controls['newPassword'].valueChanges.subscribe(() => {
      if (this.step !== 0) {
        this.step = 0;
        this.confirmPassword?.patchValue('');
      }
      if (this.newPassword?.invalid) {
        this.strength = this.newPassword?.errors?.['strength'];
      } else if (!this.newPassword?.errors?.['required']) {
        this.strength = 3;
      }

      // Display very weak, good, strong, best helpers
      this.indicationStyle = {
        weak: this.strength === 0,
        good: this.strength === 1,
        strong: this.strength === 2,
        best: this.strength === 3,
      };
    });
  }

  get passwordMustMatchError() {
    if (this.confirmPassword?.value.length > 0 && this.confirmPassword?.value !== this.newPassword?.value) {
      return 'Passwords must match';
    } else {
      return '';
    }
  }

  get code() {
    return this.passwordForm.get('code');
  }
  get newPassword() {
    return this.passwordForm.get('newPassword');
  }
  get confirmPassword() {
    return this.passwordForm.get('confirmPassword');
  }

  toggleNewPassword(event: any) {
    this.bShowNewPassword = !this.bShowNewPassword;
  }

  toggleConfirmPassword(event: any) {
    this.bShowConfirmPassword = !this.bShowConfirmPassword;
  }

  disableSubmit() {
    if (this.step === 0 && (this.strength === 0 || this.strength === 1)) {
      return true;
    }
    if (this.step === 1 && this.newPassword?.value !== this.confirmPassword?.value) {
      return true;
    }
    if (this.submitted) return true;
    return false;
  }

  onConfirm() {
    if (this.step === 0 && this.strength > 0) {
      // this.passwordForm.addControl('confirmPassword', new FormControl('', Validators.required));
      this.step = 1;
    } else if (this.step === 1 && this.newPassword?.value === this.confirmPassword?.value) {
      this.submitted = true;
      this.addSubscription = this.authService
        .forgotPasswordSubmit(this.token, this.passwordForm?.value.newPassword)
        .pipe(
          catchError((error) => {
            console.error(error);

            if (error.code) {
              if (error.code.msg === AuthException.INVALID_VERIFICATION_CODE) {
                this.authErrMsg = 'Invalid reset url provided.';
              } else if (error.code.msg === AuthException.RESET_TOKEN_EXPIRED) {
                this.authErrMsg = 'Expired reset link';
              } else if (error.code.msg === AuthException.INVALID_TOKEN) {
                this.authErrMsg = 'Invalid token';
              } else if (error.code.fullError) {
                this.authErrMsg = error.code.fullError;
              } else {
                this.authErrMsg = 'Unknown Error';
              }
            } else {
              this.authErrMsg = 'Unknown Error';
            }
            return of(null);
          }),
          finalize(() => {
            this.submitted = false;
            this.step = 2;
          })
        )
        .subscribe();
    }
  }

  close() {
    this.router.navigate(['']);
    this.closeEvent.emit();
    this.bsModalRef.hide();
  }
}
