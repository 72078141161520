import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormValidationService {
  passwordStrength = new BehaviorSubject<number>(0);

  emailValidator(): ValidatorFn | null {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const regex =
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
      const valid = regex.test(control.value);
      return valid ? null : { invalidEmail: true };
    };
  }

  nameValidator(): ValidatorFn | null {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const regex = /^[a-zA-Z0-9'\.-]+$/;
      const valid = regex.test(control.value);
      return valid ? null : { invalidName: true };
    };
  }

  /**
   * Validates the password to meet min requirements: one lower case letter, one upper case letter, one number, one valid special character, minimum 10 characters
   * @returns An object of the form:
   *
   * @example
   * // Properties are accessible with on password.errors? object:
   * {
   *    password.errors?.invalid: boolean,
   *    password.errors?.invalidChars: string,
   *    password.errors?.needLowercaseChar: boolean,
   *    password.errors?.needUppercaseChar: boolean,
   *    password.errors?.needNumber: boolean,
   *    password.errors?.needSpecialChar: boolean,
   *    password.errors?.needMinLength: boolean,
   *    password.errors?.needBestLength: boolean
   * }
   */
  passwordValidator(): ValidatorFn | null {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const passwordReqs = {
        invalid: false,
        invalidChars: '',
        needLowercaseChar: true,
        needUppercaseChar: true,
        needNumber: true,
        needSpecialChar: true,
        needMinLength: true,
        // needBestLength: true,
      };
      if (!control.value) {
        return null;
      }
      if (/[a-z]/.test(control.value)) {
        passwordReqs.needLowercaseChar = false;
      }
      if (/[A-Z]/.test(control.value)) {
        passwordReqs.needUppercaseChar = false;
      }
      if (/\d/.test(control.value)) {
        passwordReqs.needNumber = false;
      }
      if (/[!@#$%^&*()\-_=+<>?]/.test(control.value)) {
        passwordReqs.needSpecialChar = false;
      }
      if (/[A-Za-z\d!@#$%^&*()\-_=+<>?]{10,}/.test(control.value)) {
        passwordReqs.needMinLength = false;
      }
      // if (/[A-Za-z\d!@#$%^&*()\-_=+<>?]{16,}/.test(control.value)) {
      //   passwordReqs.needBestLength = false;
      // }
      if (/[`~\[\]\\{}\|;':",\.\/\s]+/g.test(control.value)) {
        passwordReqs.invalid = true;
        passwordReqs.invalidChars = control.value
          .match(/[`~\[\]\\{}\|;':",\.\/\s]+/g)
          .join('')
          .split('')
          .join(' ');
        if (control.value.includes(' ')) passwordReqs.invalidChars += ' space';
      }

      let strength = 0;
      if (passwordReqs.invalid) {
        strength = 0;
      }

      const containsError = Object.values(passwordReqs).filter((val) => {
        if (val) return val;
        return false;
      });

      switch (containsError.length) {
        case 0:
          strength = 3;
          break;
        case 1:
          strength = 2;
          break;
        case 2:
          strength = 1;
          break;
        case 3:
          strength = 0;
          break;
        default:
          strength = 0;
          break;
      }
      this.passwordStrength.next(strength);

      return containsError.length === 0 ? null : { ...passwordReqs, strength };
    };
  }

  matchValidator(matchTo: string, reverse?: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.parent && reverse) {
        const c = (control.parent?.controls as any)[matchTo] as AbstractControl;
        if (c) {
          c.updateValueAndValidity();
        }
        return null;
      }
      return !!control.parent &&
        !!control.parent.value &&
        control.value === (control.parent?.controls as any)[matchTo].value
        ? null
        : { matching: true };
    };
  }

  phoneNumberValidator(): ValidatorFn | null {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      // if (options && options.mask) {
      //   const regex = /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/;
      //   const valid = regex.test(control.value);
      //   return valid ? null : { invalidPhoneNumber: true };
      // } else {
      return control.value.length == 10 ? null : { invalidPhoneNumber: true };
      // }
    };
  }
}
