import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { Unsubscriber } from '../extenders/unsubscriber';

import { AuthService } from './auth.service';
import { Listing, Provider } from '../models/listing.model';

import { ChangeEmailComponent } from 'src/app/shared/components/modals/auth/change-email/change-email.component';
import { CreateNewPasswordComponent } from 'src/app/shared/components/modals/auth/create-new-password/create-new-password.component';
import { ChangePasswordComponent } from 'src/app/shared/components/modals/auth/change-password/change-password.component';
import { ForgotPasswordComponent } from 'src/app/shared/components/modals/auth/forgot-password/forgot-password.component';
import { GalleryModalComponent } from 'src/app/shared/components/modals/gallery-modal/gallery-modal.component';
import { MobileFiltersComponent } from 'src/app/shared/components/modals/mobile-filters/mobile-filters.component';
import { NeedAgentComponent } from 'src/app/shared/components/modals/need-agent/need-agent.component';
import { PoliciesComponent } from 'src/app/shared/components/modals/policy/policies/policies.component';
import { PrivacyPolicyComponent } from 'src/app/shared/components/modals/policy/privacy-policy/privacy-policy.component';
import { RequestConfirmModalComponent } from 'src/app/shared/components/modals/request-confirm-modal/request-confirm-modal.component';
import { SignInComponent } from 'src/app/shared/components/modals/auth/sign-in/sign-in.component';
import { SignUpComponent } from 'src/app/shared/components/modals/auth/sign-up/sign-up.component';
import { SupportComponent } from 'src/app/shared/components/modals/support/support.component';
import { TermsConditionsComponent } from 'src/app/shared/components/modals/policy/terms-conditions/terms-conditions.component';
import { UserMenuComponent } from 'src/app/shared/components/menus/user-menu/user-menu.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService extends Unsubscriber {
  authorized: boolean = false;
  activeModal = new BehaviorSubject<BsModalRef<any> | null>(null);

  constructor(private authService: AuthService, private modalService: BsModalService) {
    super();

    this.addSubscription = this.authService.authorized.subscribe((authorized) => {
      this.authorized = authorized;
    });
  }

  hide(id?: string | number) {
    this.modalService.hide(id);
  }

  /**
   * Displays the login modal.
   * @returns A ref to the displayed modal.
   */
  showLoginModal(title = 'Great to have you back!', description = 'Please Sign in to continue') {
    return this.modalService.show(SignInComponent, {
      class: 'modal-dialog-centered auth-modal',
      backdrop: 'static',
      keyboard: false,
      initialState: {
        title,
        description,
      },
    });
  }

  /**
   * Displays the register modal.
   * @returns A ref to the displayed modal.
   */
  showRegisterModal(title = 'Create Your Free Account') {
    return this.modalService.show(SignUpComponent, {
      class: 'modal-dialog-centered auth-modal auth-register',
      backdrop: 'static',
      keyboard: false,
      initialState: {
        title,
      },
    });
  }

  /**
   * Displays the change password modal.
   * @param token
   * @returns A ref to the modal.
   */
  showChangePasswordModal(token?: string) {
    return this.modalService.show(ChangePasswordComponent, {
      class: 'modal-dialog-centered auth-modal',
      backdrop: 'static',
      initialState: {
        // token,
      },
    });
  }

  /**
   * Displays the forgot password modal.
   * @returns A ref to the modal.
   */
  showForgotPasswordModal() {
    return this.modalService.show(ForgotPasswordComponent, {
      class: 'modal-dialog-centered auth-modal',
      backdrop: 'static',
      initialState: {
        // token,
      },
    });
  }

  /**
   * Displays the change email modal.
   * @param token
   * @returns A ref to the modal.
   */
  showChangeEmailModal(token?: string) {
    return this.modalService.show(ChangeEmailComponent, {
      class: 'modal-dialog-centered auth-modal',
      backdrop: 'static',
      initialState: {
        // token,
      },
    });
  }

  /**
   * Displays the create new password modal.
   * @param token
   * @returns A ref to the modal.
   */
  showCreateNewPasswordModal(token: string) {
    return this.modalService.show(CreateNewPasswordComponent, {
      class: 'modal-dialog-centered auth-modal',
      backdrop: 'static',
      keyboard: false,
      initialState: {
        token,
      },
    });
  }

  showGalleryModal(listing: Listing, provider: Provider) {
    return this.modalService.show(GalleryModalComponent, {
      class: 'modal-dialog-centered modal-xl',
      initialState: {
        listing,
        provider,
      },
    });
  }

  showPrivacyPolicyModal() {
    return this.modalService.show(PrivacyPolicyComponent, {
      class: 'info-modal modal-dialog-centered',
    });
  }

  showTermsConditionsModal() {
    return this.modalService.show(TermsConditionsComponent, {
      class: 'info-modal modal-dialog-centered',
    });
  }

  showPoliciesModal() {
    return this.modalService.show(PoliciesComponent, {
      class: 'info-modal modal-dialog-centered',
    });
  }

  showNeedAgentModal() {
    if (this.authorized) {
      this.modalService.show(NeedAgentComponent, {
        class: 'modal-dialog-centered modal-wide',
      });
    } else {
      const authModal = this.showLoginModal();
      this.addSubscription = authModal.onHidden?.subscribe(() => {
        if (this.authorized) {
          this.modalService.show(NeedAgentComponent, {
            class: 'modal-dialog-centered modal-wide',
          });
        }
      });
    }
  }

  showSupportModal() {
    if (this.authorized) {
      this.modalService.show(SupportComponent, {
        class: 'modal-dialog-centered modal-wide',
      });
    } else {
      const authModal = this.showLoginModal();
      this.addSubscription = authModal.onHidden?.subscribe(() => {
        if (this.authorized) {
          this.modalService.show(SupportComponent, {
            class: 'modal-dialog-centered modal-wide',
          });
        }
      });
    }
  }

  /**
   * Displays the confirmation modal with either a success or error message.
   * @param hasError Pass true if an error is caught when posting to this.activityService.sendRequest
   */
  showConfirmationModal(
    hasError: boolean = false,
    successMessage: string = 'A Home Captain Concierge agent will follow up with you soon.',
    failureMessage: string = 'Your request failed. Please try again in a few seconds.'
  ) {
    return this.modalService.show(RequestConfirmModalComponent, {
      class: 'request-confirm modal-dialog-centered',
      initialState: {
        hasError,
        successMessage,
        failureMessage,
      },
    });
  }

  showMobileFiltersModal() {
    return this.modalService.show(MobileFiltersComponent, { class: 'modal-dialog-centered' });
  }

  showMobileMenuModal() {
    return this.modalService.show(UserMenuComponent, { class: 'modal-dialog-centered' });
  }

  // showAuthModal(initialState: any) {
  //   if (!initialState) {
  //     initialState = {
  //       category: AuthModalCategory.LOGIN,
  //       title: 'We Found Your Property!',
  //       description: 'Please Sign in to continue',
  //       icon: 'assets/images/auth/logo2.png',
  //       hideClose: true,
  //     };
  //   }
  //   return this.modalService.show(AuthModalComponent, {
  //     class: 'modal-dialog-centered auth-modal',
  //     backdrop: 'static',
  //     keyboard: false,
  //     initialState,
  //   });
  // }
}
