<div cdkTrapFocus style="margin: 1rem">
  <div style="margin-bottom: 1rem">
    <h3>{{ title }}</h3>
    <p class="text-m">{{ description }}</p>
  </div>
  <button autofocused class="close-btn focus-link" aria-label="Close" (click)="closeModal()">
    <img src="assets/icons/close.svg" alt="Close the get support form" />
  </button>
  <form class="row" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="col-12 col-md-6">
      <hch-input
        placeholder="First Name"
        formControlName="firstName"
        field="firstName"
        [dirty]="firstName?.dirty"
        [errorInvalid]="firstName?.errors?.['invalidName']"
        [errorRequired]="firstName?.errors?.['required']"
      ></hch-input>
      <hch-input
        placeholder="Last Name"
        formControlName="lastName"
        field="lastName"
        [dirty]="lastName?.dirty"
        [errorInvalid]="lastName?.errors?.['invalidName']"
        [errorRequired]="lastName?.errors?.['required']"
      ></hch-input>
      <hch-input
        placeholder="Email"
        formControlName="email"
        field="email"
        [dirty]="email?.dirty"
        [errorInvalid]="email?.errors?.['invalidEmail']"
        [errorRequired]="email?.errors?.['required']"
      ></hch-input>
      <hch-input
        placeholder="Phone Number"
        formControlName="phone"
        field="phone"
        mask="(000) 000-0000"
        [dirty]="phone?.dirty"
        [errorInvalid]="phone?.errors?.['invalidPhoneNumber']"
        [errorRequired]="phone?.errors?.['required']"
      ></hch-input>
    </div>
    <div class="col-12 col-md-6">
      <hch-textarea placeholder="How can we help you?" formControlName="message"></hch-textarea>
    </div>
    <div class="col-12 form-footer">
      <button class="hch-btn hch-btn-primary submit-btn" [disabled]="!form.valid || submitting" aria-label="Submit">
        {{ submitting ? 'Submitting . . .' : 'Submit' }}
      </button>
    </div>
  </form>
</div>
