export enum AuthModalCategory {
  LOGIN = 'login',
  REGISTER = 'register',
  FORGOT_PASSWORD = 'forgot_password',
  CREATE_NEW_PASSWORD = 'create_new_password',
  CHANGE_PASSWORD = 'change_password',
  CHANGE_EMAIL = 'change_email',
}

export enum ScheduleModalCategory {
  SCHEDULE_TOUR = 'schedule-tour',
  GET_INFO = 'get-info',
}

export enum AuthException {
  EMAIL_WRONG_FORMAT = 'EMAIL_IS_IN_WRONG_FORMAT',
  EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED',
  WRONG_PASSWORD = 'WRONG_PASSWORD',
  USER_DOES_NOT_EXIST = 'USER_DOES_NOT_EXIST',
  USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
  INVALID_VERIFICATION_CODE = 'INVALID_VERIFICATION_CODE',
  RESET_TOKEN_EXPIRED = 'RESET_TOKEN_EXPIRED',
  INVALID_TOKEN = 'INVALID_TOKEN',
  USERNAME_OR_PASSWORD_INCORRECT = 'USERNAME_OR_PASSWORD_INCORRECT',
}
