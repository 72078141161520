import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleMapsModule } from '@angular/google-maps';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CookieService } from 'ngx-cookie-service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { AppRoutingModule } from './app.routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';

import { environment } from 'src/environments/environment';
import { RequestInterceptor } from './core/interceptors/request-interceptor';

const ANGULAR_MODULES = [
  AppRoutingModule,
  BrowserAnimationsModule,
  BrowserModule,
  CommonModule,
  FormsModule,
  GoogleMapsModule,
  HttpClientModule,
  HttpClientJsonpModule,
  ReactiveFormsModule,
];
const MODULES = [
  AccordionModule.forRoot(),
  BsDatepickerModule.forRoot(),
  BsDropdownModule.forRoot(),
  CarouselModule.forRoot(),

  ModalModule.forRoot(),
  NgbModule,
  NgxSkeletonLoaderModule.forRoot(),
  SharedModule,
  TabsModule.forRoot(),
  TooltipModule.forRoot(),
];

@NgModule({
  declarations: [AppComponent],
  imports: [...ANGULAR_MODULES, ...MODULES],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.GOOGLE_RECAPCHA_V3_SITE_KEY,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    provideEnvironmentNgxMask(),
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
