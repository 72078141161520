import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { BehaviorSubject, combineLatest, of, map, switchMap } from 'rxjs';

import { Unsubscriber } from '../core/extenders/unsubscriber';
import { DropdownItem, sortByItem, sortByItems } from '../core/models/dropdown.model';
import { Listing, ListingFavorite, Provider } from '../core/models/listing.model';
import { UserFavorite, UserModel, UserSearch } from '../core/models/user.model';

import { ActivityService } from '../core/services/activity.service';
import { AuthService } from '../core/services/auth.service';
import { SearchService } from '../core/services/search.service';
import { UrlParserService } from '../core/services/url-parser.service';

@Injectable({
  providedIn: 'root',
})
export class FavoritesService extends Unsubscriber {
  favorites = new BehaviorSubject<{ [key: string]: Provider }>({});
  hasFavorites = new BehaviorSubject<boolean>(false);
  listings = new BehaviorSubject<Listing[]>([]);

  searches = new BehaviorSubject<UserSearch[]>([]);

  sortByItems = new BehaviorSubject<DropdownItem[]>(sortByItems);
  sortByItem = new BehaviorSubject<DropdownItem>(sortByItem);

  constructor(
    private activityService: ActivityService,
    private authService: AuthService,
    private searchService: SearchService,
    private urlParserService: UrlParserService
  ) {
    super();

    this.addSubscription = combineLatest([this.authService.currentUser, this.sortByItem])
      .pipe(
        switchMap(([user, sort]) => {
          if (user && user.searches && user.searches.length) {
            this.searches.next(user.searches);
          }
          if (user && user.favorites && user.favorites.length) {
            this.favorites.next(this.setFavorites(user.favorites));
            this.hasFavorites.next(true);
            return this.getListings(sort);
          }
          this.favorites.next({});
          this.listings.next([]);
          this.hasFavorites.next(false);
          return of(null);
        })
      )
      .subscribe();
  }

  setFavorites(favorites: UserFavorite[]) {
    let newFavs: { [key: string]: Provider } = {};
    for (let fav of favorites) {
      newFavs[fav.id] = fav.provider;
    }
    return newFavs;
  }

  removeSavedSearch(search: UserSearch) {
    const searches = this.authService.currentUser.getValue()?.searches!;
    const newSearches = searches.filter((s) => {
      return s.name != search.name;
    });
    this.addSubscription = this.activityService
      .updateSearches(newSearches)
      .pipe(
        map((user: UserModel) => {
          this.searches.next(user.searches);
        })
      )
      .subscribe();
  }

  viewSavedSearch(search: UserSearch) {
    const params: Params = { ...search, page: 1 };
    if (params['name']) delete params['name'];
    if (params['postalCode']) delete params['postalCode'];
    this.urlParserService.addParams(params);
  }

  getListings(sort: DropdownItem) {
    const favorites = Object.entries(this.favorites.getValue());
    let userFavorites: ListingFavorite[] = [];
    for (let fav of favorites) {
      userFavorites.push({
        id: fav[0],
        provider: fav[1] as Provider,
      });
    }
    return this.searchService.getFavoritedListings(userFavorites, String(sort.value)).pipe(
      map((data) => {
        if (data) {
          this.favorites.next(this.setFavorites(data.activeFavorites));
          this.listings.next(data.favorites);
        }
      })
    );
  }
}
