import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'src/app/core/helpers/auth.guard';

import { BuyLayoutComponent } from 'src/app/shared/layout/buy-layout/buy-layout.component';
import { FullListingLayoutComponent } from 'src/app/shared/layout/full-listing-layout/full-listing-layout.component';
import { HomeLayoutComponent } from 'src/app/shared/layout/home-layout/home-layout.component';
import { HomeValueLayoutComponent } from 'src/app/shared/layout/home-value-layout/home-value-layout.component';
import { MainLayoutComponent } from 'src/app/shared/layout/main-layout/main-layout.component';

const nmlsRoutes: Routes = [
  {
    path: 'home/:nmls',
    pathMatch: 'full',
    title: 'Real Estate, Homes for Sale, MLS Listings, Home Value Estimates | Home Captain',
    component: HomeLayoutComponent,
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: ':nmls/buy',
    component: BuyLayoutComponent,
    loadChildren: () => import('./buy/buy.module').then((m) => m.BuyModule),
  },
  {
    path: ':nmls/homevalue',
    title: 'Find Your Home’s Value | Home Captain',
    component: HomeValueLayoutComponent,
    loadChildren: () => import('./home-value/home-value.module').then((m) => m.HomeValueModule),
  },
  {
    path: ':nmls/team',
    component: MainLayoutComponent,
    loadChildren: () => import('./team/team.module').then((m) => m.TeamModule),
  },
  {
    path: ':nmls/favorite',
    component: MainLayoutComponent,
    title: 'My Saved Properties and Searches | Home Captain',
    canActivate: [AuthGuard],
    loadChildren: () => import('./favorites/favorites.module').then((m) => m.FavoritesModule),
  },
  {
    path: ':nmls/full-listing',
    component: FullListingLayoutComponent,
    loadChildren: () => import('./full-listing/full-listing.module').then((m) => m.FullListingModule),
  },
  {
    path: ':nmls/settings',
    component: MainLayoutComponent,
    title: 'My Account Settings | Home Captain',
    canActivate: [AuthGuard],
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: ':nmls/licenses',
    component: MainLayoutComponent,
    loadChildren: () => import('./licenses/licenses.module').then((m) => m.LicensesModule),
  },
  {
    path: ':nmls/affordability',
    component: MainLayoutComponent,
    loadChildren: () => import('./affordability/affordability.module').then((m) => m.AffordabilityModule),
  },
];

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'buy',
        component: BuyLayoutComponent,
        loadChildren: () => import('./buy/buy.module').then((m) => m.BuyModule),
      },
      {
        path: 'full-listing',
        component: FullListingLayoutComponent,
        loadChildren: () => import('./full-listing/full-listing.module').then((m) => m.FullListingModule),
      },
      {
        path: 'homevalue',
        title: 'Find Your Home’s Value | Home Captain',
        component: HomeValueLayoutComponent,
        loadChildren: () => import('./home-value/home-value.module').then((m) => m.HomeValueModule),
      },
      {
        path: 'team',
        component: MainLayoutComponent,
        loadChildren: () => import('./team/team.module').then((m) => m.TeamModule),
      },
      {
        path: 'favorite',
        component: MainLayoutComponent,
        title: 'My Saved Properties and Searches | Home Captain',
        canActivate: [AuthGuard],
        loadChildren: () => import('./favorites/favorites.module').then((m) => m.FavoritesModule),
      },
      {
        path: 'settings',
        component: MainLayoutComponent,
        title: 'My Account Settings | Home Captain',
        canActivate: [AuthGuard],
        loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'licenses',
        component: MainLayoutComponent,
        loadChildren: () => import('./licenses/licenses.module').then((m) => m.LicensesModule),
      },
      {
        path: 'affordability',
        component: MainLayoutComponent,
        loadChildren: () => import('./affordability/affordability.module').then((m) => m.AffordabilityModule),
      },
      ...nmlsRoutes,
      {
        path: '',
        component: HomeLayoutComponent,
        title: 'Real Estate, Homes for Sale, MLS Listings, Home Value Estimates | Home Captain',
        loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
