import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';
import { HomeValueService } from 'src/app/home-value/home-value.service';
import { RoutingService } from 'src/app/core/services/routing.service';

@Component({
  selector: 'hch-home-value-layout',
  templateUrl: './home-value-layout.component.html',
  styleUrls: ['./home-value-layout.component.scss'],
})
export class HomeValueLayoutComponent extends Unsubscriber implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private homeValueService: HomeValueService,
    private routingService: RoutingService
  ) {
    super();
  }

  ngOnInit(): void {
    this.addSubscription = this.route.params.subscribe((params) => {
      this.routingService.setActivatedRoute(params);
    });
    this.addSubscription = this.route.queryParams.subscribe((params) => {
      this.homeValueService.initialize(params);
    });
  }
}
