<div dropdown #dropdown="bs-dropdown" class="menu-dropdown">
  <button
    dropdownToggle
    class="dropdown-toggle"
    style="background-color: transparent"
    aria-expanded="dropdown.isOpen"
    [id]="'menu-dropdown-' + id"
    (click)="focusFirstItem()"
  >
    <!-- [ngClass]="{ black: theme === 'black', blue: theme === 'blue' }" -->
    {{ title }}
  </button>
  <ul
    *dropdownMenu
    class="dropdown-menu"
    role="menu"
    (keydown.arrowdown)="focusNextItem($event)"
    (keydown.arrowup)="focusPrevItem($event)"
    (keydown.tab)="focusNextItem($event)"
    (keydown.shift.tab)="focusPrevItem($event)"
  >
    <li *ngFor="let item of items; let i = index" role="menuitem" style="display: flex">
      <a
        *ngIf="item.link"
        class="menu-item focus-link"
        [id]="'menu-dropdown-' + id + '-item-' + i"
        [href]="[item.link]"
      >
        <img *ngIf="item.icon" [src]="item.icon" alt="menu item" />
        <span>{{ item.label }}</span>
      </a>
      <button *ngIf="!item.link" class="menu-item focus-link" [id]="'menu-dropdown-' + id + '-item-' + i">
        <!-- (click)="item.callback && item.callback(bsModalService, 'menu-dropdown-' + id)" -->
        <img *ngIf="item.icon" [src]="item.icon" alt="menu item" />
        <span>{{ item.label }}</span>
      </button>
    </li>
  </ul>
</div>
