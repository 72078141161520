<div cdkTrapFocus style="margin: 1rem">
  <h3>{{ title }}</h3>
  <p>{{ description }}</p>
  <button
    autofocused
    class="close-btn focus-link"
    (click)="close()"
    aria-label="Close connect with a realtor concierge modal"
  >
    <img src="assets/icons/close.svg" alt="Close connect with a realtor concierge modal" />
  </button>
  <form class="row" [formGroup]="form">
    <div class="col-12 col-md-6">
      <hch-input
        placeholder="First Name"
        formControlName="firstName"
        field="firstName"
        [dirty]="firstName?.dirty"
        [errorInvalid]="firstName?.errors?.['invalidName']"
        [errorRequired]="firstName?.errors?.['required']"
      ></hch-input>
      <hch-input
        placeholder="Last Name"
        formControlName="lastName"
        field="lastName"
        [dirty]="lastName?.dirty"
        [errorInvalid]="lastName?.errors?.['invalidName']"
        [errorRequired]="lastName?.errors?.['required']"
      ></hch-input>
      <hch-input
        placeholder="Email"
        formControlName="email"
        field="email"
        [dirty]="email?.dirty"
        [errorInvalid]="email?.errors?.['invalidEmail']"
        [errorRequired]="email?.errors?.['required']"
      ></hch-input>
      <hch-input
        placeholder="Phone Number"
        formControlName="phone"
        field="phone"
        mask="(000) 000-0000"
        [dirty]="phone?.dirty"
        [errorInvalid]="phone?.errors?.['invalidPhoneNumber']"
        [errorRequired]="phone?.errors?.['required']"
      ></hch-input>
    </div>
    <div class="col-12 col-md-6">
      <hch-input
        placeholder="Enter Your Address: Street, City, State, Zip"
        formControlName="address"
        field="address"
        [dirty]="address?.dirty"
        [errorRequired]="address?.errors?.['required']"
      ></hch-input>
      <hch-floating-dropdown
        placeholder="I’m Interested In"
        formControlName="interest"
        category="INTEREST"
        [items]="interestOptions"
        [styles]="elemStyle"
      ></hch-floating-dropdown>
      <hch-floating-dropdown
        placeholder="Time Frame"
        formControlName="timeFrame"
        category="TIMEFRAME"
        [items]="timelineOptions"
        [styles]="elemStyle"
      ></hch-floating-dropdown>
    </div>
    <div class="col-12 form-footer">
      <button class="hch-btn hch-btn-primary submit-btn" [disabled]="form.invalid || submitting" (click)="onSubmit()">
        {{ submitting ? 'Submitting . . .' : 'Submit' }}
      </button>
    </div>
  </form>
</div>
