import { Component, Input, OnInit, forwardRef, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { DropdownItem, DropdownOptionsE } from 'src/app/core/models/dropdown.model';

@Component({
  selector: 'hch-floating-dropdown',
  templateUrl: './floating-dropdown.component.html',
  styleUrls: ['./floating-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FloatingDropdownComponent),
      multi: true,
    },
  ],
})
export class FloatingDropdownComponent implements OnInit, ControlValueAccessor {
  @Output() change = new EventEmitter<DropdownItem>();
  @Output() isOpen = new EventEmitter<boolean>();

  private _value: DropdownItem = {
    label: '',
    value: '',
  };

  get value(): DropdownItem {
    return this._value;
  }

  @Input()
  set value(val: DropdownItem) {
    this._value = val;
    this.selectedItem = val;
  }

  @Input() category?: keyof typeof DropdownOptionsE = 'TIMEFRAME';
  @Input() disabled: boolean = false;
  @Input() icon: string = ''; // icon url
  @Input() items: DropdownItem[] = [];
  @Input() placeholder: string = '';
  @Input() styles: any;

  selectedItem: DropdownItem = {
    label: '',
    value: '',
  };

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    if (!this.items) {
      this.disabled = true;
    } else if (this.items.length === 0) {
      this.disabled = true;
    } else if (this.items.length != 0) {
      if (this._value && this._value.label) {
        this.selectedItem = this._value;
      } else {
        this.selectedItem = this.items[0];
      }
    }
  }

  emitIsOpen(isOpen: boolean) {
    if (isOpen) this.isOpen.emit(true);
    else {
      this.isOpen.emit(false);
      setTimeout(() => {
        document.getElementById(`dropdown-${this.category}`)?.focus();
      }, 0);
    }
  }

  /**
   * Focuses the selected item in the dropdown when opened.
   * Makes it work async by placing it in setTimeout with 0ms.
   */
  focusSelectedItem() {
    setTimeout(() => {
      let indexOfItem = this.items.map((item) => item.label).indexOf(this.selectedItem.label);
      if (indexOfItem < 0) indexOfItem = 0;
      document.getElementById(`dropdown-${this.category}-item-${indexOfItem}`)?.focus();
    }, 0);
  }

  /**
   * Enables the next item in the custom dropdown to be focused using the arrow keys or tab or shift+tab. Needed for ADA compliance.
   * @param event
   */
  focusNextItem(e: Event) {
    const event = e as KeyboardEvent;
    if (event.key !== 'Tab') event.preventDefault();
    if (this.selectedItem) {
      let indexOfItem = this.items.map((item) => item.label).indexOf(this.selectedItem.label);
      if (indexOfItem !== -1 && this.items.length > indexOfItem + 1) {
        this.selectedItem = this.items[indexOfItem + 1];
        if (event.key !== 'Tab') document.getElementById(`dropdown-${this.category}-item-${indexOfItem + 1}`)?.focus();
      }
    } else {
      this.selectedItem = this.items[0];
      if (event.key !== 'Tab') document.getElementById(`dropdown-${this.category}-item-0`)?.focus();
    }
  }

  /**
   * Enables the previous item in the custom dropdown to be focused using the arrow keys or tab or shift+tab. Needed for ADA compliance.
   * @param event
   */
  focusPrevItem(e: Event) {
    const event = e as KeyboardEvent;
    if (event.key !== 'Tab') event.preventDefault();
    if (this.selectedItem) {
      let indexOfItem = this.items.map((item) => item.label).indexOf(this.selectedItem.label);
      if (indexOfItem !== -1 && indexOfItem > 0) {
        this.selectedItem = this.items[indexOfItem - 1];
        if (event.key !== 'Tab') document.getElementById(`dropdown-${this.category}-item-${indexOfItem - 1}`)?.focus();
      }
    } else {
      this.selectedItem = this.items[0];
      if (event.key !== 'Tab') document.getElementById(`dropdown-${this.category}-item-0`)?.focus();
    }
  }

  selectItem(item: DropdownItem) {
    this.selectedItem = item;
    this.change.emit(this.selectedItem);
    this.onChange(item);
    this.onTouched(item);
  }

  writeValue(value: any): void {
    this.selectedItem = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
