<ng-container
  *ngIf="{
    buyParams: routingService.buyQueryParams | async,
    staticParams: routingService.staticQueryParams | async,
    authorized: authService.authorized | async,
    hasFavorites: favoritesService.hasFavorites | async
  } as state"
>
  <header class="page-header">
    <div class="page-header-wrapper container">
      <hch-logo></hch-logo>
      <ul class="navbar-nav">
        <li class="nav-item">
          <a
            routerLinkActive="nav-item-active"
            [routerLink]="routingService.buyUrl | async"
            [attr.aria-current]="currentRoute == 'buy'"
            [queryParams]="routingService.mergeParams(state.buyParams, state.staticParams)"
          >
            Buy
          </a>
        </li>
        <li class="nav-item">
          <a
            routerLinkActive="nav-item-active"
            [routerLink]="routingService.homeValueUrl | async"
            [routerLinkActiveOptions]="{ exact: false }"
            [attr.aria-current]="currentRoute == 'homevalue'"
            [queryParams]="state.staticParams"
          >
            Home Value
          </a>
        </li>
        <li *ngIf="routingService.teamUrl | async" class="nav-item">
          <a
            routerLinkActive="nav-item-active"
            [routerLink]="routingService.teamUrl | async"
            [routerLinkActiveOptions]="{ exact: false }"
            [attr.aria-current]="currentRoute == 'team'"
            [queryParams]="state.staticParams"
          >
            Our Team
          </a>
        </li>
        <li class="nav-item">
          <a
            routerLinkActive="nav-item-active"
            [routerLink]="routingService.calcURL | async"
            [attr.aria-current]="currentRoute == 'affordability'"
            [queryParams] = "state.staticParams"
          >
            Affordability Calculator
          </a>
        </li>
      </ul>
      <div class="user-menu">
        <div class="menu-item btn-sign-in" aria-current="page" *ngIf="!state.authorized" (click)="login()">
          <button class="btn btn-link" id="sign-in-btn">Sign In</button>
        </div>
        <div class="menu-item" *ngIf="!state.authorized" aria-current="step">
          <button id="register" class="hch-btn hch-btn-primary" (click)="register()">Register</button>
        </div>
        <div class="menu-item">
          <a
            aria-label="favorites"
            routerLinkActive="favorite-active"
            [routerLink]="routingService.favoriteUrl | async"
            [routerLinkActiveOptions]="{ exact: true }"
            [attr.aria-current]="currentRoute == 'favorite'"
            [queryParams]="state.staticParams"
          >
            <img *ngIf="!state.hasFavorites; else favorites" class="heart" src="assets/images/icons/heart.svg" alt="" />
            <ng-template #favorites>
              <img class="heart" src="assets/images/icons/heart-active.svg" alt="" />
            </ng-template>
          </a>
        </div>
        <div class="menu-item">
          <hch-hamburger-menu></hch-hamburger-menu>
        </div>
      </div>
    </div>
  </header>
</ng-container>
