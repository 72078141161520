import { environment } from 'src/environments/environment';

export const listhubReportingTagSingle = (listingKey: string) => {
  const isTest = !environment.production;
  return `
      (function(l,i,s,t,h,u,b){l['ListHubAnalyticsObject']=h;l[h]=l[h]||function(){
        (l[h].q=l[h].q||[]).push(arguments)},l[h].d=1*new Date();u=i.createElement(s),
        b=i.getElementsByTagName(s)[0];u.async=1;u.src=t;b.parentNode.insertBefore(u,b)
      })(window,document,'script','//tracking.listhub.net/la.min.js','lh');

      lh('init', {provider:'${environment.LISTHUB_ID}', test:${isTest}});
      lh('submit', 'DETAIL_PAGE_VIEWED', {lkey:'${listingKey}'});`;
};

export const listhubReportingTagMultiple = (listingKeys: any[]) => {
  const isTest = !environment.production;
  const keys = listingKeys.map((key) => {
    return { lkey: `${key.id}` };
  });

  return `
      (function(l,i,s,t,h,u,b){l['ListHubAnalyticsObject']=h;l[h]=l[h]||function(){
        (l[h].q=l[h].q||[]).push(arguments)},l[h].d=1*new Date();u=i.createElement(s),
        b=i.getElementsByTagName(s)[0];u.async=1;u.src=t;b.parentNode.insertBefore(u,b)
      })(window,document,'script','//tracking.listhub.net/la.min.js','lh');

      lh('init', {provider:'${environment.LISTHUB_ID}', test:${isTest}});
      lh('submit', 'SEARCH_DISPLAY', ${JSON.stringify(keys)});
  `;
};
