import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StatsService {
  provider = environment.REALSTAQ;

  constructor(private http: HttpClient) {}

  getStats(listingId: string) {
    let params = new HttpParams();
    params = params.set('listingId', listingId);
    return this.http.get(environment.HUBAPI_BASE_URL + '/stats', {
      params,
    });
  }

  viewListing(listingId: string, provider: string) {
    return this.http.post(environment.HUBAPI_BASE_URL + '/stats/view', {
      provider,
      listingId,
    });
  }

  saveListing(listingId: string, provider: string, isSaved: boolean) {
    return this.http.post(environment.HUBAPI_BASE_URL + '/stats/favorite', {
      provider,
      listingId,
      action: isSaved ? 'save' : 'unsave',
    });
  }
}
