import { Pipe, PipeTransform } from '@angular/core';

/**
 * Appends zeroes to the front of a zip code if needed.
 *
 * @example
 * {{ (queryParams.Zip | zipcode) }}
 */
@Pipe({
  name: 'zipcode',
})
export class ZipCodePipe implements PipeTransform {
  transform(value: string): any {
    return zipCode(value);
  }
}

export function zipCode(value: string) {
  let zip = Number(value);
  if (!isNaN(zip)) {
    if (zip < 10) return '0000' + String(zip);
    else if (zip < 100) return '000' + String(zip);
    else if (zip < 1000) return '00' + String(zip);
    else if (zip < 10000) return '0' + String(zip);
  }
  return value;
}
