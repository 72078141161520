import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, finalize, throwError } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { AuthService } from 'src/app/core/services/auth.service';
import { FormValidationService } from 'src/app/core/services/form-validation.service';
import { ActivityService } from 'src/app/core/services/activity.service';

import { UserRequest, UserRequestsE } from 'src/app/core/models/user.model';
import { interestOptionsAgent, interestOptions, timelineOptions } from 'src/app/core/models/dropdown.model';
import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';

@Component({
  selector: 'hch-need-agent',
  templateUrl: './need-agent.component.html',
  styleUrls: ['./need-agent.component.scss'],
})
export class NeedAgentComponent extends Unsubscriber implements OnInit {
  elemStyle = {
    'margin-bottom': '1.5rem',
  };

  interestOptions = interestOptionsAgent;
  timelineOptions = timelineOptions;

  title = '';
  description = '';
  short = false;

  bEditing = false;
  submitting = false;

  form: FormGroup;

  constructor(
    public bsModalRef: BsModalRef,
    public authService: AuthService,
    private fb: FormBuilder,
    private activityService: ActivityService,
    private formValidationService: FormValidationService
  ) {
    super();

    this.form = this.fb.group({
      firstName: ['', Validators.compose([Validators.required, this.formValidationService.nameValidator()])],
      lastName: ['', Validators.compose([Validators.required, this.formValidationService.nameValidator()])],
      email: ['', Validators.compose([Validators.required, this.formValidationService.emailValidator()])],
      phone: ['', Validators.compose([Validators.required, this.formValidationService.phoneNumberValidator()])],
      address: ['', Validators.required],
      interest: [this.interestOptions[0]],
      timeFrame: [this.timelineOptions[0]],
    });
  }

  ngOnInit() {
    if (this.short) {
      this.interestOptions = interestOptions;
    }

    this.addSubscription = this.authService.currentUser.subscribe((user) => {
      if (user) {
        const [firstName, lastName] = user.fullName.split(' ');
        // Do not patch value if a value already exists in the form:
        if (
          this.firstName?.value.length == 0 &&
          this.lastName?.value.length == 0 &&
          this.email?.value.length == 0 &&
          this.phone?.value.length == 0
        ) {
          this.form.patchValue({
            firstName,
            lastName,
            email: user.email,
            phone: user.phoneNumber.replace(/[^0-9]+/g, ''),
          });
          this.form.updateValueAndValidity();
        }
        this.title = "Let's get you connected.";
        this.description = 'Our concierge will contact you soon!';
        // this.mobileTitle = 'Request Sent!';
        // this.mobileDescription =
        //   'Our team will contact you shortly. In the meantime, tell us a little bit more about yourself.';
      } else {
        this.title = "Let's get you connected.";
        this.description = "Let's get you connected to our concierge to get started.";
        // this.mobileTitle = 'Need An Agent?';
        // this.mobileDescription = "Let's get you connected to our concierge to get started.";
      }
    });
  }

  get firstName() {
    return this.form.get('firstName');
  }
  get lastName() {
    return this.form.get('lastName');
  }
  get phone() {
    return this.form.get('phone');
  }
  get email() {
    return this.form.get('email');
  }
  get address() {
    return this.form.get('address');
  }
  get interest() {
    return this.form.get('interest')?.value;
  }
  get timeFrame() {
    return this.form.get('timeFrame')?.value;
  }

  onSubmit() {
    if (this.form.invalid) return;

    this.submitting = true;
    let data: UserRequest = {
      // jungoId: this.currentUser.jungoId,
      firstName: this.firstName?.value,
      lastName: this.lastName?.value,
      email: this.email?.value,
      phone: this.phone?.value,
      address: this.address?.value,
      interest: this.interest.name,
      timeFrame: this.timeFrame.name,
      needAgent: true,
    };

    let hasError = false;

    this.addSubscription = this.activityService
      .sendRequest(UserRequestsE.NEED_REAL_ESTATE_AGENT, data)
      .pipe(
        catchError((error) => {
          hasError = true;
          document.getElementById('close-request-modal')?.focus();
          return throwError(() => error);
        }),
        finalize(() => {
          this.submitting = false;
          // Display confirmation modal
          this.bsModalRef.hide();
          this.activityService.displayConfirmationModal(hasError);
        })
      )
      .subscribe();
  }

  close() {
    this.bsModalRef.hide();
    document.getElementById('hamburger-icon')?.focus();
  }
}
