import { Component, OnInit } from '@angular/core';

import { RoutingService } from 'src/app/core/services/routing.service';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'hch-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {
  constructor(public modalService: ModalService, public routingService: RoutingService) {}

  ngOnInit(): void {}
}
