<div
  role="group"
  aria-labelledby="bedrooms-label"
  class="position-relative"
  dropdown
  *ngIf="!mobile"
  #dropdownBeds="bs-dropdown"
>
  <button
    id="beds-filter"
    class="btn btn-outline-primary btn-md dropdown-toggle"
    dropdownToggle
    aria-label="Bedrooms Range"
  >
    {{ selectedBed.value == -1 ? 'Beds' : selectedBed.label + ' Beds' }}
  </button>
  <div *dropdownMenu class="dropdown-menu">
    <div class="dropdown-body">
      <div
        *ngFor="let item of bedsOptions"
        class="option"
        tabindex="0"
        (click)="changeBedRoom(item); dropdownBeds.hide()"
        (keydown.enter)="changeBedRoom(item); dropdownBeds.hide()"
        [ngClass]="{ selected: selectedBed.value === item.value }"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</div>

<div *ngIf="mobile">
  <hch-stepper
    [options]="bedsOptions"
    [selectedOption]="selectedBed"
    (changeEvent)="changeBedRoom($event)"
  ></hch-stepper>
</div>
