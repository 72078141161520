import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
// import { ChartData, ChartOptions } from 'chart.js';

import { DropdownItem } from 'src/app/core/models/dropdown.model';
import { Listing } from 'src/app/core/models/listing.model';
import { MortgageCalculatorService } from 'src/app/core/services/mortgage-calculator.service';
import { FullListingService } from '../../../../full-listing/full-listing.service';

@Component({
  selector: 'hch-payment-calculator',
  templateUrl: './payment-calculator.component.html',
  styleUrls: ['./payment-calculator.component.scss'],
})
export class PaymentCalculatorComponent implements OnInit, AfterViewInit {
  downPrice = 0;
  downRate = 20;
  interestRate = 7.0;
  presentPrice = 0;
  loanPeriod = 30;
  monthlyPayment = 0;

  listing?: Listing;

  loanTypes: DropdownItem[] = [
    { label: '30-year fixed', value: 30 },
    { label: '20-year fixed', value: 20 },
    { label: '15-year fixed', value: 15 },
    { label: '10-year fixed', value: 10 },
    { label: '5-year fixed', value: 5 },
  ];

  // chartData?: ChartData<'doughnut', number[]>;
  // chartOptions?: ChartOptions;

  @Input()
  type: 'compact' | 'full' = 'compact';

  constructor(
    public fullListingService: FullListingService,
    private mortgageCalculatorService: MortgageCalculatorService
  ) {
    // this.chartOptions = {
    //   responsive: true,
    //   maintainAspectRatio: false,
    // };
  }

  ngOnInit(): void {
    this.fullListingService.listing.subscribe((listing) => {
      this.listing = listing;
    });

    this.presentPrice = Number(this.listing?.price) || 0;
    this.downPrice = (this.presentPrice * this.downRate) / 100;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.monthlyPayment = this.mortgageCalculatorService.setMonthlyPayment(
        this.presentPrice - this.downPrice,
        this.interestRate,
        this.loanPeriod
      );
    });
  }

  changeLoan($event: DropdownItem) {
    this.loanPeriod = $event.value as number;
  }

  downRateValue() {
    return Math.round((this.downPrice / this.presentPrice) * 10000) / 100;
  }

  changeDownPrice() {
    const price = Number(this.listing?.price);
    if (price) {
      if (this.downPrice > price) {
        this.downPrice = price;
      } else if (this.downPrice < 0) {
        this.downPrice = 0;
      }
      this.downRate = this.downRateValue();
    }
  }

  changeDownRate() {
    if (this.downRate > 100) {
      this.downRate = 100;
    } else if (this.downRate < 0) {
      this.downRate = 0;
    }
    this.downPrice = (this.presentPrice * this.downRate) / 100;
  }

  changeInterestRate() {
    if (this.interestRate > 100) {
      this.interestRate = 100;
    } else if (this.interestRate < 0) {
      this.interestRate = 0;
    }
  }
}
