import { Injectable } from '@angular/core';

export const HCH_FAVORITE_ITEMS_STORAGE = 'hch-favorite-items-storage';
export const HCH_TEMP_SAVE_SEARCH = 'hch-temp-save-search';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  saveFavoriteItem(item: any) {
    localStorage.setItem(HCH_FAVORITE_ITEMS_STORAGE, JSON.stringify(item));
  }

  saveTempSearch(option: any) {
    localStorage.setItem(HCH_TEMP_SAVE_SEARCH, JSON.stringify(option));
  }

  getTempSearch() {
    try {
      const item = localStorage.getItem(HCH_TEMP_SAVE_SEARCH);
      if (item) return JSON.parse(item);
      return null;
    } catch (error) {
      return null;
    }
  }

  getFavoriteItem() {
    try {
      const item = localStorage.getItem(HCH_FAVORITE_ITEMS_STORAGE);
      if (item) return JSON.parse(item);
      return null;
    } catch (error) {
      return null;
    }
  }

  removeTempSearch() {
    localStorage.removeItem(HCH_TEMP_SAVE_SEARCH);
  }

  removeFavoriteItem() {
    localStorage.removeItem(HCH_FAVORITE_ITEMS_STORAGE);
  }

  cacheListings(queryParams: string, listingObj: any) {
    sessionStorage.setItem(queryParams, JSON.stringify(listingObj));
  }

  getCachedListings(queryParams: string) {
    try {
      const params = sessionStorage.getItem(queryParams);
      if (params) return JSON.parse(params);
      return null;
    } catch (error) {
      return null;
    }
  }
}
