import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tonumber',
})
export class NumberPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let num = Number(value);
    for (let arg of args) {
      if (arg == 'ceil') num = Math.ceil(value);
      if (arg == 'floor') num = Math.floor(value);
    }
    return num;
  }
}
