import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { RoutingService } from 'src/app/core/services/routing.service';

export enum SearchParams {
  size = 'size',
  number = 'number',
  page = 'page',
  textT = 'text',
  highlighted = 'highlighted',
  view = 'view',
  listings = 'listings',
  listingsExcluded = 'listingsExcluded',
  propertyTypes = 'propertyTypes',
  statuses = 'statuses',
  city = 'city',
  cityId = 'cityId',
  agentUid = 'agentUid',
  officeUid = 'officeUid',
  state = 'state',
  zip = 'zip',
  zipId = 'zipId',
  mlses = 'mlses',
  mlsListings = 'mlsListings',
  minBedrooms = 'minBedrooms',
  maxBedrooms = 'maxBedrooms',
  minBathrooms = 'minBathrooms',
  maxBathrooms = 'maxBathrooms',
  minPrice = 'minPrice',
  maxPrice = 'maxPrice',
  minYearBuilt = 'minYearBuilt',
  maxYearBuilt = 'maxYearBuilt',
  minSqFt = 'minSqFt',
  maxSqFt = 'maxSqFt',
  minLotSize = 'minLotSize',
  maxLotSize = 'maxLotSize',
  minPhotos = 'minPhotos',
  neighborhoodId = 'neighborhoodId',
  schoolDistrictId = 'schoolDistrictId',
  position = 'position',
  distance = 'distance',
  box = 'box',
  sort = 'sort',
  since = 'since',
  userId = 'userId',
  boxNorthWest = 'boxNorthWest',
  boxSouthEast = 'boxSouthEast',
}

export enum HomeValueSearchParams {
  Address = 'Address',
  City = 'City',
  State = 'State',
  County = 'County',
  Zip = 'Zip',
  ZipCode4 = 'ZipCode4',
}

@Injectable({
  providedIn: 'root',
})
export class UrlParserService {
  constructor(private route: ActivatedRoute, private router: Router, private routingService: RoutingService) {}

  get urlObject() {
    return this.route.snapshot.queryParams;
  }

  setParams(params: Params, url = [this.routingService.buyUrl.getValue()]) {
    this.router.navigate(url, {
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }

  addParam(key: string, value: string | number, url = [this.routingService.buyUrl.getValue()]) {
    this.router.navigate(url, {
      queryParams: {
        [key]: value,
        page: '1',
      },
      queryParamsHandling: 'merge',
    });
  }

  changePage(value: string | number, url = [this.routingService.buyUrl.getValue()]) {
    let paramsMap = {
      page: value,
    };
    this.router.navigate(url, {
      queryParams: paramsMap,
      queryParamsHandling: 'merge',
    });
  }

  addParams(params: Params, url = [this.routingService.buyUrl.getValue()]) {
    let paramsMap = {
      page: '1',
      ...params,
    };
    this.router.navigate(url, {
      queryParams: paramsMap,
      queryParamsHandling: 'merge',
    });
  }

  removeParam(key: string, url = [this.routingService.buyUrl.getValue()], id?: string, campaignid?: string) {
    let paramsMap: Params = {
      [key]: null,
    };
    this.router.navigate(url, {
      queryParams: paramsMap,
      queryParamsHandling: 'merge',
    });
  }

  removeParams(keys: Params, url = [this.routingService.buyUrl.getValue()]) {
    const params: Params = {};
    this.router.navigate(url, {
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }
}
