<ng-container
  *ngIf="{
    buyParams: routingService.buyQueryParams | async,
    staticParams: routingService.staticQueryParams | async
  } as state"
>
  <div class="mobile-menu">
    <button
      class="menu-item"
      [routerLink]="routingService.buyUrl | async"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: false }"
      [queryParams]="routingService.mergeParams(state.buyParams, state.staticParams)"
    >
      <div class="menu-img-container">
        <img src="assets/icons/buy.svg" alt="buy" class="normal-icon" />
        <img src="assets/icons/buy-blue.svg" alt="buy" class="active-icon" />
      </div>
      <div class="menu-text">Buy</div>
    </button>
    <button
      class="menu-item"
      routerLinkActive="active"
      [routerLink]="routingService.homeValueUrl | async"
      [routerLinkActiveOptions]="{ exact: false }"
      [queryParams]="state.staticParams"
      style="flex: 1.2"
    >
      <div class="menu-img-container">
        <img src="assets/icons/home-value.svg" alt="buy" class="normal-icon" />
        <img src="assets/icons/home-value-blue.svg" alt="buy" class="active-icon" />
      </div>
      <div class="menu-text">Home Value</div>
    </button>
    <!-- <div class="menu-item" routerLink="/sell" routerLinkActive="active"
    [routerLinkActiveOptions]="{exact: true}">
    <div class="menu-img-container">
      <img src="assets/icons/sell.svg" alt="buy" class="normal-icon" />
      <img src="assets/icons/sell-blue.svg" alt="buy" class="active-icon" />
    </div>
    <div class="menu-text">Sell</div>
  </div> -->
    <button class="menu-item" (click)="modalService.showMobileMenuModal()">
      <div class="menu-img-container">
        <img src="assets/icons/more.svg" alt="buy" class="normal-icon" />
        <img src="assets/icons/more-blue.svg" alt="buy" class="active-icon" />
      </div>
      <div class="menu-text">More</div>
    </button>
  </div>
</ng-container>
