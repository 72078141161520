import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';

import { AuthService } from 'src/app/core/services/auth.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { RoutingService } from 'src/app/core/services/routing.service';

@Component({
  selector: 'hch-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent extends Unsubscriber implements OnInit {
  constructor(
    public authService: AuthService,
    private modalService: ModalService,
    public routingService: RoutingService,
    public bsModalRef: BsModalRef
  ) {
    super();
  }

  ngOnInit(): void {}

  login() {
    this.modalService.showLoginModal();
  }

  register() {
    this.modalService.showRegisterModal('Create Your Free Account');
  }

  logout() {
    const q = this.routingService.staticQueryParams.getValue();
    const p = this.routingService.params.getValue();
    this.authService.logout(q, p).subscribe();
  }

  onRealEstateAgent() {
    this.modalService.showNeedAgentModal();
  }

  onSupport() {
    this.modalService.showSupportModal();
  }
}
