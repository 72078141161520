<button
  class="favorite focus-link"
  (click)="favoriteItem()"
  [ngClass]="{
    saved: savedCard,
    updating: updating,
    'btn btn-outline-primary favorite-text': display == 'TEXT',
    'favorite-icon': display == 'ICON'
  }"
>
  <img *ngIf="savedCard && display == 'TEXT'" src="assets/images/icons/heart-active.svg" alt="" />
  <img *ngIf="!savedCard && display == 'TEXT'" src="assets/icons/favorite-white.svg" alt="Un-favorite home" />
  <span *ngIf="display == 'TEXT'" style="padding-left: 0.5rem">
    {{ savedCard ? 'Unsave' : 'Save' }}
  </span>

  <img *ngIf="savedCard && display == 'ICON'" src="assets/icons/favorite-white.svg" alt="Un-favorite home" />
  <img *ngIf="!savedCard && display == 'ICON'" src="assets/icons/favorite.svg" alt="Favorite home" />
</button>
