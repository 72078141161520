import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { catchError, finalize } from 'rxjs';
import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';

import { UserRequest, UserRequestsE } from 'src/app/core/models/user.model';
import { ActivityService } from 'src/app/core/services/activity.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { FormValidationService } from 'src/app/core/services/form-validation.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { FullListingService } from 'src/app/full-listing/full-listing.service';

@Component({
  selector: 'hch-get-info',
  templateUrl: './get-info.component.html',
  styleUrls: ['./get-info.component.scss'],
})
export class GetInfoComponent extends Unsubscriber implements OnInit {
  errorMessage = '';
  submitting = false;
  form: FormGroup;

  constructor(
    private bsModalRef: BsModalRef,
    private authService: AuthService,
    private activityService: ActivityService,
    private fb: FormBuilder,
    private formValidationService: FormValidationService,
    private fullListingService: FullListingService,
    public modalService: ModalService
  ) {
    super();

    this.form = this.fb.group({
      firstName: ['', Validators.compose([Validators.required, this.formValidationService.nameValidator()])],
      lastName: ['', Validators.compose([Validators.required, this.formValidationService.nameValidator()])],
      email: ['', Validators.compose([Validators.required, this.formValidationService.emailValidator()])],
      phone: ['', Validators.compose([Validators.required, this.formValidationService.phoneNumberValidator()])],
      message: ['', Validators.required],
    });
  }

  get firstName() {
    return this.form.get('firstName');
  }
  get lastName() {
    return this.form.get('lastName');
  }
  get email() {
    return this.form.get('email');
  }
  get phone() {
    return this.form.get('phone');
  }
  get message() {
    return this.form.get('message');
  }

  ngOnInit(): void {
    this.addSubscription = this.authService.currentUser.subscribe((user) => {
      if (user) {
        const [firstName, lastName] = user.fullName.split(' ');
        // Do not patch value if a value already exists in the form:
        if (
          this.firstName?.value.length == 0 &&
          this.lastName?.value.length == 0 &&
          this.email?.value.length == 0 &&
          this.phone?.value.length == 0
        ) {
          this.form.patchValue({
            firstName,
            lastName,
            email: user.email,
            phone: user.phoneNumber.replace(/[^0-9]+/g, ''),
          });
        }
        this.form.updateValueAndValidity();
      }
    });
  }

  close() {
    this.bsModalRef.hide();
  }

  submit() {
    if (this.form.invalid) return;
    this.submitting = true;
    let error = false;
    const data: UserRequest = {
      firstName: this.firstName?.value,
      lastName: this.lastName?.value,
      email: this.email?.value,
      phone: this.phone?.value,
      address: this.fullListingService.listing.getValue()?.address,
      interest: 'Buying',
      message: this.message?.value,
    };
    this.addSubscription = this.activityService
      .sendRequest(UserRequestsE.GET_INFO, data)
      .pipe(
        catchError((error) => {
          console.error(error);
          error = true;
          return error;
        }),
        finalize(() => {
          this.form.reset();
          this.submitting = false;
          this.modalService.showConfirmationModal(error);
        })
      )
      .subscribe();
  }
}
