import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, catchError, finalize, map, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

import { defaultListingModel, Listing, ListingModel, ProviderE } from '../core/models/listing.model';
import { DropdownItem, sortByItem, sortByItems } from '../core/models/dropdown.model';
import { listhubReportingTagMultiple } from '../core/scripts/listhub-reporting';
import { states } from '../core/data/state-options';
import { Unsubscriber } from '../core/extenders/unsubscriber';
import { MediaService } from 'src/app/core/services/media.service';
import { RoutingService } from 'src/app/core/services/routing.service';
import { SearchService } from '../core/services/search.service';
import { SearchParams, UrlParserService } from '../core/services/url-parser.service';

@Injectable({
  providedIn: 'root',
})
export class BuyService extends Unsubscriber {
  showStateSelector = new BehaviorSubject(false);
  showSearch = new BehaviorSubject(true);
  loading = new BehaviorSubject(false);
  initialSearch = new BehaviorSubject(true);

  pageInfo = new BehaviorSubject<ListingModel | undefined>(undefined);
  listings = new BehaviorSubject<Listing[]>([]);

  city = new BehaviorSubject('');
  state = new BehaviorSubject('');
  position = new BehaviorSubject('');
  distance = new BehaviorSubject('1');

  filterCount = new BehaviorSubject(0);
  sortByItem = sortByItem;
  sortByItems = sortByItems;
  stateOptions = states;

  previousSearch = new BehaviorSubject<Params | null>(null);

  constructor(
    private cookieService: CookieService,
    private mediaService: MediaService,
    private router: Router,
    private routingService: RoutingService,
    private searchService: SearchService,
    private titleService: Title,
    private urlParserService: UrlParserService
  ) {
    super();
    this.titleService.setTitle('Homes For Sale | Home Captain');
    // this.addSubscription = this.mediaService.STATE_SELECTOR.subscribe((value) => {
    //   if (value) {
    //     this.showStateSelector.next(true);
    //     this.showSearch.next(false);
    //   } else {
    //     this.showStateSelector.next(false);
    //     this.showSearch.next(true);
    //   }
    // });
  }

  updateSortBy(event: DropdownItem) {
    this.sortByItem = event;
    this.urlParserService.addParam(SearchParams.sort, this.sortByItem.value);
  }

  changePagination(event: any) {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.urlParserService.changePage(event.page, [this.routingService.buyUrl.getValue()]);
  }

  applyFilter() {
    const queryParams = this.routingService.buyQueryParams.getValue();
    this.router.navigate(['/buy'], { queryParams });
  }

  resetFilter() {
    const params = this.routingService.buyQueryParams.getValue();
    if (params) {
      this.urlParserService.removeParams(params);
    }
  }

  changeAddress(event: Params) {
    this.router.navigate([this.routingService.buyUrl.getValue()], { queryParams: event });
  }

  /**
   * Handles the bulk of the logic for the Buy page by subscribing to the changes in the router's query params.
   * Loads the data from the buyService if params are stored in service and the same as the incoming params.
   */
  initialize(params: Params) {
    if (Object.keys(params).length == 0) {
      return;
    }
    if ((!params['state'] || (!params['state'] && params['city'])) && !(params['position'] && params['distance'])) {
      return;
    }

    this.loading.next(true);
    this.addSubscription = this.setParams(params).subscribe();
  }

  /**
   * Compares incoming params with params in routingService state.
   * If same, load from memory.
   * If different, load from API.
   * @param params
   */
  setParams(params: Params) {
    this.cookieService.set('hch.state', JSON.stringify(params), {
      expires: 2147483647,
      path: '/',
      sameSite: 'Lax',
      secure: true,
    });

    this.routingService.buyQueryParams.next(params);

    this.titleService.setTitle('Homes For Sale | Home Captain');
    let title: string = '';

    let filterLen = Object.keys(params).length;
    if (params['state']) {
      this.state.next(params['state']);
      filterLen -= 1;
      // Update select element with state in params
      const state = this.stateOptions.filter((state) => state.postal === params['state'])[0];
      title = state.text;
    }
    if (params['city']) {
      this.city.next(params['city']);
      filterLen -= 1;
      title = title + ', ' + params['city'];
    }
    if (params['position']) {
      this.position.next(params['position']);
      filterLen -= 1;
    }
    if (params['distance']) {
      this.distance.next(params['distance']);
      filterLen -= 1;
    }
    if (params['mode']) {
      filterLen -= 1;
    }
    if (params['page']) {
      // this.pageInfo.currentPage = parseInt(params['page']);
      // this.pageInfo.currentPageNew = parseInt(params['page']);
      // this.pageInfo.pageNumber = params['page'];
      filterLen -= 1;
      const currentInfo = this.pageInfo.getValue();
      if (currentInfo) {
        this.pageInfo.next({
          ...currentInfo,
          pageNumber: params['page'],
        });
      }
    }
    if (params['sort']) {
      this.sortByItem = this.sortByItems.filter((item) => item.value == params['sort'])[0];
    } else {
      this.sortByItem = this.sortByItems[0];
    }

    this.titleService.setTitle(title + ' Homes For Sale | Home Captain');
    this.filterCount.next(filterLen);

    if (params['state']) {
      return this.getListings();
    } else if (params['position'] && params['distance']) {
      return this.getNearbyListings();
    }
    return of(null);
  }

  /**
   * Returns an Observable with the listings search logic.
   */
  getListings() {
    this.initialSearch.next(false);

    return this.searchService.getListings().pipe(
      map((model) => {
        if (model) {
          this.pageInfo.next(model);
          this.listings.next(model.listings);
          if (model.provider === ProviderE.LISTHUB) {
            this.addListhubReportingScript(model);
          }
        } else {
          this.pageInfo.next(defaultListingModel);
          this.listings.next([]);
        }
      }),
      catchError((error) => {
        console.error(error);
        this.pageInfo.next(defaultListingModel);
        this.listings.next([]);
        return error;
      }),
      finalize(() => {
        this.loading.next(false);
      })
    );
  }

  getNearbyListings() {
    this.initialSearch.next(false);

    const distance = this.distance.getValue();
    const position = this.position.getValue().split(',');
    const latitude = Number(position[0]);
    const longitude = Number(position[1]);

    return this.searchService.getNearByData(latitude, longitude, distance).pipe(
      map((model) => {
        if (model) {
          this.pageInfo.next(model);
          this.listings.next(model.listings);
          if (model.provider === ProviderE.LISTHUB) {
            this.addListhubReportingScript(model);
          }
        } else {
          this.pageInfo.next(defaultListingModel);
          this.listings.next([]);
        }
      }),
      catchError((error) => {
        console.error(error);
        this.pageInfo.next(defaultListingModel);
        this.listings.next([]);
        return error;
      }),
      finalize(() => {
        this.loading.next(false);
      })
    );
  }

  addListhubReportingScript(model: ListingModel) {
    const listhubScript = listhubReportingTagMultiple(model.listings);
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = listhubScript;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }
}
