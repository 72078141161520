import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, catchError, finalize, map, throwError } from 'rxjs';

import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';
import { UserModel } from '../models/user.model';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  currentUser = new BehaviorSubject<UserModel | null>(null);
  authorized = new BehaviorSubject<boolean>(false);
  refreshing = new BehaviorSubject<boolean>(false);

  AUTHAPI_BASE_URL = environment.HUBAPI_BASE_URL + '/auth';

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) {}

  updateLocalUserInfo(user: UserModel) {
    this.currentUser.next(user);
  }

  public get currentUserValue() {
    return this.currentUser.getValue();
  }

  public get isLogin() {
    if (this.authorized.getValue() && this.currentUser.getValue()) return true;
    return false;
  }

  /**
   * On page load or refresh, hit backend to see if user has a valid jwt.
   * If no jwt exists, do nothing.
   * If valid jwt, authorize user with res.body
   */
  refresh() {
    this.refreshing.next(true);
    return this.http.get(environment.HUBAPI_BASE_URL + '/refresh', { observe: 'response' }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
      map((res: HttpResponse<any>) => {
        if (res.body && res.body.user && res.body.user.jungoId) {
          this.authorized.next(true);
          this.currentUser.next(res.body.user);
        } else {
          this.authorized.next(false);
          this.currentUser.next(null);
        }
      }),
      finalize(() => {
        this.refreshing.next(false);
      })
    );
  }

  /**
   * Register a new user in the HCH database. Once the user is received, register the user in Jungo.
   */
  registerUser(input: {
    email: string;
    password: string;
    phoneNumber: string;
    fullName: string;
    id?: string;
    campaignid?: string;
  }) {
    const { email, phoneNumber, password, fullName, id, campaignid } = input;
    const user: any = {
      email,
      phoneNumber,
      password,
      fullName,
    };
    if (id) user['id'] = id;
    if (campaignid) user['campaignid'] = campaignid;
    return this.http.post(this.AUTHAPI_BASE_URL + '/register', user);
  }

  /**
   * Registers a new user with the given loan officer information. Used by Citi for MR registrations.
   */
  registerUserMR(input: {
    email: string;
    phoneNumber: string;
    password: string;
    fullName: string;
    nmls: string;
    loanOfficerEmail: string;
    loanOfficerName: string;
    id?: string;
    campaignid?: string;
  }) {
    const { email, phoneNumber, fullName, password, nmls, loanOfficerEmail, loanOfficerName, id, campaignid } = input;
    const user: any = {
      email,
      phoneNumber,
      password,
      fullName,
      nmls,
      loEmail: loanOfficerEmail,
      loName: loanOfficerName,
    };
    if (id) user['id'] = id;
    if (campaignid) user['campaignid'] = campaignid;

    return this.http.post(this.AUTHAPI_BASE_URL + '/register', user);
  }

  resendVerificationCode(email: string) {
    return this.http.post(this.AUTHAPI_BASE_URL + '/resend-verification', {
      email,
    });
  }

  verifyEmail(userName: string, verifyCode: string) {
    return this.http.post(this.AUTHAPI_BASE_URL + '/verify-email', {
      email: userName,
      code: verifyCode,
    });
  }

  /**
   * Sign in an existing user.
   */
  signInWithEmail(input: { email: string; password: string; token?: string }) {
    const { email, password, token } = input;
    const authInfo = {
      email,
      password,
      token,
    };
    return this.http.post(this.AUTHAPI_BASE_URL + '/login', authInfo, { observe: 'response' }).pipe(
      map((data: HttpResponse<any>) => {
        if (data) {
          this.updateLocalUserInfo(data.body.user);
          this.authorized.next(true);
          return data.body.user;
        }
      })
    );
  }

  /**
   * Checks if user already exists in HCH database.
   */
  userExist(email: string, token = '') {
    return this.http.post(this.AUTHAPI_BASE_URL + '/check-email', {
      email,
      token,
    });
  }

  /**
   * Manually formats the return url for logging out and returning to the site's home page.
   * @param queryParams From the routingService.queryParams
   * @param params From the routingService.params
   * @returns An object with the formatted params: { qp, p }
   */
  formatLogoutUrl(queryParams: Params = {}, params: Params = {}) {
    let qp = '';
    let p = '';
    if (Object.keys(queryParams).length != 0) {
      qp += '?';
      Object.entries(queryParams).forEach(([k, v], i) => {
        qp += k + '=' + v;
        if (i != Object.entries(queryParams).length - 1) {
          qp += '&';
        }
      });
    }
    if (Object.keys(params).length != 0) {
      Object.entries(params).forEach(([k, v]) => {
        if (k == 'nmls') p += '/home/' + v;
      });
    }
    return {
      qp,
      p,
    };
  }

  /**
   * Logout the user and clear data.
   */
  logout(queryParams: Params = {}, params: Params = {}) {
    this.localStorageService.removeTempSearch();
    this.currentUser.next(null);
    this.authorized.next(false);
    const { qp, p } = this.formatLogoutUrl(queryParams, params);
    return this.http.get(this.AUTHAPI_BASE_URL + '/logout').pipe(
      map(() => {
        window.location.href = window.location.origin + p + qp;
      })
    );
  }

  /**
   * Request a password reset. Sends an email to the user with a link to reset their password.
   * @returns An observable of the HTTP request.
   */
  forgotPassword(email: string) {
    return this.http.post(this.AUTHAPI_BASE_URL + '/forgot-password', {
      email,
    });
  }

  /**
   * After user enters a new password, send updated info and verification code to HCH database.
   * @returns An observable of the HTTP request.
   */
  forgotPasswordSubmit(code: string, password: string) {
    return this.http
      .post(this.AUTHAPI_BASE_URL + '/complete-forgot-password', { code, password }, { observe: 'response' })
      .pipe(
        map((data: HttpResponse<any>) => {
          if (data) {
            this.updateLocalUserInfo(data.body.user);
            return data.body.user;
          }
        })
      );
  }

  /**
   * Updates the user's full name and phone number on the Settings page.
   * @returns An observable of the HTTP request.
   */
  updateUserInfo(fullName: string, phoneNumber: string) {
    return this.http
      .post(this.AUTHAPI_BASE_URL + '/update-profile', {
        fullName,
        phoneNumber,
      })
      .pipe(
        map((data: any) => {
          if (data) {
            this.updateLocalUserInfo(data.user);
            return data.user;
          }
        })
      );
  }

  /**
   * Allow the user to change their password when logged in.
   */
  changePassword(oldPassword: string, newPassword: string) {
    return this.http.post(this.AUTHAPI_BASE_URL + '/change-password', {
      oldPassword,
      password: newPassword,
    });
  }

  /**
   * Request an email change. Sends an email to the new email address to confirm the change.
   */
  changeEmail(email: string, newEmail: string) {
    return this.http.post(this.AUTHAPI_BASE_URL + '/change-email', { email, newEmail }).pipe(
      map((data: any) => {
        if (data) {
          this.updateLocalUserInfo(data.user);
          return data.user;
        }
      })
    );
  }

  /**
   * CURRENTLY NOT IMPLEMENTED
   *
   * After user clicks the link in the email to reset their email and changes their email in the app, confirm the change and update the HCH database.
   */
  completeChangeEmail(code: string) {
    return this.http.post(this.AUTHAPI_BASE_URL + '/complete-change-email', { code }).pipe(
      map((data: any) => {
        if (data) {
          this.updateLocalUserInfo(data.user);
          return data.user;
        }
      })
    );
  }

  /**
   * Returns the account info.
   */
  getAccountInfo() {
    return this.http.get(this.AUTHAPI_BASE_URL + '/account').pipe(
      map((data: any) => {
        if (data) {
          this.updateLocalUserInfo(data.user);
          return data.user;
        }
      })
    );
  }

  /**
   * Updates user settings
   */
  updateSettings(settings: any) {
    return this.http.post(this.AUTHAPI_BASE_URL + '/update-settings', { settings }).pipe(
      map((data: any) => {
        if (data) {
          this.updateLocalUserInfo(data.user);
          return data.user;
        }
      })
    );
  }

  /**
   * Saves user's selected state to database.
   */
  saveSelectedState(state: string) {
    return this.http.post(this.AUTHAPI_BASE_URL + '/select-state', { state }).pipe(
      map((data: any) => {
        if (data) {
          this.updateLocalUserInfo(data.user);
          return data.user;
        }
      })
    );
  }

  // HELPER METHODS ===============================================================================

  /**
   * Formats and returns a formatted phone number to display in the form
   */
  formatPhoneNumber(phoneNumberString: string): string {
    if (!phoneNumberString) return '';
    if (phoneNumberString.indexOf('-') !== -1) return phoneNumberString;

    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return '';
  }
}
