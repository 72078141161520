import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

export interface MenuDropdownItem {
  label: string;
  icon?: string;
  link?: string;
  value?: string;
  callback?: () => {};
}

@Component({
  selector: 'hch-menu-dropdown',
  templateUrl: './menu-dropdown.component.html',
  styleUrls: ['./menu-dropdown.component.scss'],
})
export class MenuDropdownComponent implements OnInit {
  @Input() items: MenuDropdownItem[] = [];
  @Input() title: string = '';
  @Input() id: string = '';
  @Input() styles: any;
  // @Input() theme: string = '';
  @Output() change = new EventEmitter();

  selectedItem?: MenuDropdownItem;

  constructor(public bsModalService: BsModalService) {}

  ngOnInit(): void {}

  focusFirstItem() {
    setTimeout(() => document.getElementById(`menu-dropdown-${this.id}-item-0`)?.focus(), 0);
  }

  /**
   * Enables the next item in the custom dropdown to be focused using the arrow keys or tab or shift+tab. Needed for ADA compliance.
   * @param event
   */
  focusNextItem(e: Event) {
    const event = e as KeyboardEvent;
    if (event.key !== 'Tab') event.preventDefault();
    if (this.selectedItem) {
      const indexOfItem = this.items.indexOf(this.selectedItem);
      if (indexOfItem !== -1 && this.items.length > indexOfItem + 1) {
        this.selectedItem = this.items[indexOfItem + 1];
        if (event.key !== 'Tab') document.getElementById(`menu-dropdown-${this.id}-item-${indexOfItem + 1}`)?.focus();
      }
    } else {
      this.selectedItem = this.items[0];
      if (event.key !== 'Tab') document.getElementById(`menu-dropdown-${this.id}-item-0`)?.focus();
    }
  }

  /**
   * Enables the previous item in the custom dropdown to be focused using the arrow keys or tab or shift+tab. Needed for ADA compliance.
   * @param event
   */
  focusPrevItem(e: Event) {
    const event = e as KeyboardEvent;
    if (event.key !== 'Tab') event.preventDefault();
    if (this.selectedItem) {
      const indexOfItem = this.items.indexOf(this.selectedItem);
      if (indexOfItem !== -1 && indexOfItem > 0) {
        this.selectedItem = this.items[indexOfItem - 1];
        if (event.key !== 'Tab') document.getElementById(`menu-dropdown-${this.id}-item-${indexOfItem - 1}`)?.focus();
      }
    } else {
      this.selectedItem = this.items[0];
      if (event.key !== 'Tab') document.getElementById(`menu-dropdown-${this.id}-item-0`)?.focus();
    }
  }
}
