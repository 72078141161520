<div cdkTrapFocus class="auth-modal__content" [ngClass]="{ 'auth-content': !changedPassword }">
  <button class="close-btn" (click)="bsModalRef.hide()">
    <img src="assets/icons/close.svg" alt="" />
  </button>
  <div *ngIf="!changedPassword">
    <h2 class="auth-title">Change Password</h2>
    <form class="auth-form" [formGroup]="form">
      <div class="password-field">
        <hch-input
          field="password"
          placeholder="Current Password"
          formControlName="currentPassword"
          [type]="showPasswords ? 'text' : 'password'"
          [dirty]="currentPassword?.dirty"
          [errorRequired]="currentPassword?.errors?.['required']"
          [errorMessage]="errorMessage"
          [maxlength]="24"
        ></hch-input>
        <button class="visible-btn focus-link" (click)="showPasswords = !showPasswords">
          <img *ngIf="!showPasswords" src="assets/icons/invisible.svg" alt="Make password invisible" />
          <img *ngIf="showPasswords" src="assets/icons/visible.svg" alt="Make password visible" />
        </button>
      </div>

      <div class="password-field">
        <hch-input
          field="password"
          placeholder="New Password"
          formControlName="newPassword"
          [type]="showPasswords ? 'text' : 'password'"
          [dirty]="newPassword?.dirty"
          [errorRequired]="newPassword?.errors?.['required']"
          [maxlength]="24"
        ></hch-input>
        <button class="visible-btn focus-link" (click)="showPasswords = !showPasswords">
          <img *ngIf="!showPasswords" src="assets/icons/invisible.svg" alt="Make password invisible" />
          <img *ngIf="showPasswords" src="assets/icons/visible.svg" alt="Make password visible" />
        </button>
      </div>

      <div class="password-strength" *ngIf="newPassword?.value.length > 0">
        <div class="strength" *ngIf="{ strength: (formValidationService.passwordStrength | async) || 0 } as state">
          <div class="strength-indications">
            <div
              class="indication"
              [ngStyle]="{ visibility: state.strength >= 0 ? 'visible' : 'hidden' }"
              [ngClass]="indicationStyle"
            ></div>
            <div
              class="indication"
              [ngStyle]="{ visibility: state.strength >= 1 ? 'visible' : 'hidden' }"
              [ngClass]="indicationStyle"
            ></div>
            <div
              class="indication"
              [ngStyle]="{ visibility: state.strength >= 2 ? 'visible' : 'hidden' }"
              [ngClass]="indicationStyle"
            ></div>
            <div
              class="indication"
              [ngStyle]="{ visibility: state.strength >= 3 ? 'visible' : 'hidden' }"
              [ngClass]="indicationStyle"
            ></div>
          </div>
          <span class="text-s" [ngClass]="indicationStyle" *ngIf="!newPassword?.errors?.['invalid']">
            {{ strengthLegend[state.strength] }}
          </span>
        </div>
        <p class="text-s" *ngIf="newPassword?.errors?.['invalid']" style="font-weight: bold">
          Contains an invalid character {{ newPassword?.errors?.['invalidChars'] }}
        </p>
        <p class="text-s" *ngIf="!newPassword?.errors?.['invalid'] && newPassword?.errors?.['needLowercaseChar']">
          Include at least one lowercase letter
        </p>
        <p class="text-s" *ngIf="!newPassword?.errors?.['invalid'] && newPassword?.errors?.['needUppercaseChar']">
          Include at least one uppecase letter
        </p>
        <p class="text-s" *ngIf="!newPassword?.errors?.['invalid'] && newPassword?.errors?.['needNumber']">
          Include at least one number
        </p>
        <p class="text-s" *ngIf="!newPassword?.errors?.['invalid'] && newPassword?.errors?.['needSpecialChar']">
          Include at least one valid special character !@#$%^&*()-_=+<>?
        </p>
        <p class="text-s" *ngIf="!newPassword?.errors?.['invalid'] && newPassword?.errors?.['needMinLength']">
          Make the password at least 10 characters long
        </p>
      </div>
      <div class="password-field" *ngIf="confirmingPassword">
        <hch-input
          field="password"
          placeholder="Confirm Password"
          formControlName="confirmPassword"
          [type]="showPasswords ? 'text' : 'password'"
          [dirty]="confirmPassword?.dirty"
          [errorRequired]="confirmPassword?.errors?.['required']"
          [errorMessage]="passwordMustMatchError"
          [maxlength]="24"
        ></hch-input>
        <button class="visible-btn focus-link" (click)="showPasswords = !showPasswords">
          <img *ngIf="!showPasswords" src="assets/icons/invisible.svg" alt="Hide password" />
          <img *ngIf="showPasswords" src="assets/icons/visible.svg" alt="Show password" />
        </button>
      </div>
      <button
        class="hch-btn hch-btn-primary form-btn"
        [disabled]="
          ((formValidationService.passwordStrength | async) || 0) != 3 ||
          passwordMustMatchError ||
          !form.valid ||
          submitting
        "
        (click)="confirm()"
      >
        {{ submitting ? 'Confirming . . .' : 'Confirm' }}
      </button>
    </form>
  </div>
  <div class="step change-success" *ngIf="changedPassword">
    <img src="assets/images/homecaptain-logo-cmyk.png" class="logo" alt="Home Captain logo" />
    <h2 class="auth-title">Password Changed!</h2>
    <img src="assets/images/auth/password-changed.png" class="done" alt="Password changed logo" />
    <!-- <p class="auth-desc text-s">You will receive an email shortly confirming your password change.</p> -->
    <button class="hch-btn hch-btn-secondary done-btn" (click)="bsModalRef.hide()">Close</button>
  </div>
</div>
