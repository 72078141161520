import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { catchError, finalize } from 'rxjs';

import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';

import { ActivityService } from 'src/app/core/services/activity.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'hch-save-search-dropdown',
  templateUrl: './save-search-dropdown.component.html',
  styleUrls: ['./save-search-dropdown.component.scss'],
})
export class SaveSearchDropdownComponent extends Unsubscriber implements OnInit {
  @Input() totalElements = 0;

  disabledSearchButton = false;
  authModal!: BsModalRef;

  @ViewChild('savedSearchDropdown') savedSearchDropdown: any;

  constructor(
    private authService: AuthService,
    private activityService: ActivityService,
    public route: ActivatedRoute,
    private modalService: ModalService
  ) {
    super();
  }

  ngOnInit(): void {}

  /**
   * Updates the search with notification settings and a name. Logs the search in HCH database and Salesforce.
   */
  saveSearch(params: Params, name: string) {
    this.disabledSearchButton = true;
    this.savedSearchDropdown.hide();
    let hasError = false;

    this.addSubscription = this.activityService
      .logSavedSearch({
        name,
        ...params,
      })
      .pipe(
        catchError((error) => {
          console.error(error);
          hasError = true;
          return error;
        }),
        finalize(() => {
          this.modalService.showConfirmationModal(
            hasError,
            'Successfully saved search: ' + name + '!',
            'Failed to save your search. Please try again in a few seconds.'
          );
          this.disabledSearchButton = false;
        })
      )
      .subscribe();
  }

  closeDropdown() {
    this.savedSearchDropdown.isOpen = false;
  }

  toggleDropdown() {
    if (this.authService.authorized.getValue()) {
      this.savedSearchDropdown.toggle(true);
    } else {
      this.authModal = this.modalService.showLoginModal();
      this.addSubscription = this.authModal.onHidden?.subscribe(() => {
        if (this.authService.authorized.getValue()) {
          this.savedSearchDropdown.toggle(true);
        }
      });
    }
  }
}
