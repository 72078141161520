<div class="buy-filter-container">
  <div class="buy-filter-header">
    <button class="filter-button buy-back" (click)="bsModalRef.hide(); buyService.resetFilter()">
      <img src="assets/icons/left-blue.svg" alt="" role="button" aria-label="Close Filter Dropdown" />
    </button>
    <h5>Add Filters</h5>
    <button class="filter-button reset" (click)="buyService.resetFilter()">reset</button>
    <!-- <a *ngIf="filters.length > 0" class="reset" (click)="buyService.resetFilter()">reset</a> -->
  </div>
  <div class="price-range">
    <h6 id="price-range" aria-label="Price Range" class="mb-3">Price Range</h6>
    <hch-price-dropdown [mobile]="true"></hch-price-dropdown>
  </div>
  <div class="stepper-container">
    <div>
      <h6 id="bedrooms-label" class="mb-3">Bedrooms</h6>
      <hch-beds-dropdown [mobile]="true"></hch-beds-dropdown>
    </div>
    <div>
      <h6 id="bathrooms-label" class="mb-3">Bathrooms</h6>
      <hch-baths-dropdown [mobile]="true"></hch-baths-dropdown>
    </div>
  </div>
  <hch-more-dropdown
    [mobile]="true"
    (changeValid)="valid = $event"
    #moreDropdown
    (closeEvent)="bsModalRef.hide()"
  ></hch-more-dropdown>
</div>
