import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { Listing, ListingModel, ListingResults, ProviderE } from '../models/listing.model';
import { FooterService } from './footer.service';
import { UrlParserService } from './url-parser.service';

const _apiMaper = {
  minPrice: 'ListPrice ge',
  maxPrice: 'ListPrice le',
  minBedrooms: 'BedroomsTotal ge',
  minBathrooms: 'BathroomsTotalInteger ge',
  minSqFt: 'LotSizeArea ge',
  maxSqFt: 'LotSizeArea le',
  minLotSize: 'LotSizeAcres ge',
  minYearBuilt: 'YearBuilt ge',
  maxYearBuilt: 'YearBuilt le',
  city: 'PostalCity eq',
  state: 'StateOrProvince eq',
};

const _orderMapper = {
  '-price': 'ListPrice desc',
  '+price': 'ListPrice asc',
  '-list_date': 'ModificationTimestamp desc',
  '+list_date': 'ModificationTimestamp asc',
  '+bedrooms': 'BedroomsTotal asc',
  '+bathrooms': 'BathroomsTotalInteger asc',
  '-square_feet': 'LotSizeArea desc',
  '-year_built': 'YearBuilt desc',
  default: 'ModificationTimestamp desc',
};

const _orderMapper2 = {
  '-list_date': 'listDate',
  '-price': 'price',
  '+price': 'price',
  '-bedrooms': 'bedrooms',
  '-bathrooms': 'bathrooms',
  '-square_feet': 'squareFeet',
  '-year_built': 'yearBuilt',
};

@Injectable({
  providedIn: 'root',
})
export class ListHubService {
  urlListhub: string = '';
  keyListhub: string = '';

  listhubAccessToken = '';
  hubAPIKey: string;

  constructor(
    private footerService: FooterService,
    private _http: HttpClient,
    private urlParserService: UrlParserService
  ) {
    this.urlListhub = environment.HUBAPI_BASE_URL + '/listhub-api';
    this.hubAPIKey = environment.HUB_API_KEY;
  }

  get provider() {
    return environment.API_TYPE;
  }

  private _getDataFromListhubDB(data: ListingResults): ListingModel {
    return {
      pageNumber: parseInt(data.page.toString()),
      elementsPerPage: data.listings.length,
      totalElements: data.total_elements,
      totalPages: Math.floor((data.total_elements - 1) / 20) + 1,
      listings: data.listings,
      provider: ProviderE.LISTHUB,
    };
  }

  getListingById(id: string) {
    let headers = new HttpHeaders();
    headers = headers.append('x-api-key', this.hubAPIKey);

    return this._http.get<Listing>(this.urlListhub + '/listings/' + id, {
      headers: headers,
    });
  }

  getListingsByIds(ids: string[], sort: string) {
    let headers = new HttpHeaders();
    headers = headers.append('x-api-key', this.hubAPIKey);

    return this._http.post<Listing[]>(
      this.urlListhub + '/listings?sort=' + encodeURIComponent(sort),
      { ids },
      {
        headers: headers,
      }
    );
  }

  getSimilarListingsById(listing: Listing, tolerance = 0.2) {
    const { listingId, price, bedrooms, bathrooms, propertyType, propertySubType, city, state } = listing;

    const priceNum = Number(price);
    const beds = bedrooms || 0;
    const baths = bathrooms || 0;

    let params = new HttpParams();
    let query = this.urlParserService.urlObject;
    for (let key in query) {
      params = params.append(key, query[key]);
    }
    params = params.append('city', city!);
    params = params.append('state', state!);
    params = params.append('count', '3');
    params = params.append('price', price!);
    params = params.append('minPrice', JSON.stringify(Math.round(priceNum - priceNum * tolerance)));
    params = params.append('maxPrice', JSON.stringify(Math.round(priceNum + priceNum * tolerance)));
    params = params.append('minBedrooms', JSON.stringify(Math.round(beds - beds * tolerance)));
    params = params.append('propertyType', propertyType!);
    params = params.append('propertySubType', propertySubType || '');
    params = params.append('minBathrooms', JSON.stringify(Math.round(baths - baths * tolerance)));
    params = params.append('listingId', listingId!);

    return this._http.get<Listing[]>(this.urlListhub + '/similarListings', {
      params: params,
    });
  }

  getListings() {
    // Clear MLS data and full listing url data in footer
    this.footerService.setMlsIcon('');
    this.footerService.setMlsInfo('');
    this.footerService.setListingUrl('');

    let params = new HttpParams();
    let headers = new HttpHeaders();

    headers = headers.append('x-api-key', this.hubAPIKey);
    params = params.append('$count', 'true');

    let query = this.urlParserService.urlObject;

    for (let key in query) {
      params = params.append(key, query[key]);
    }
    if (!params.get('page')) {
      params = params.append('page', '1');
    }

    return this._http
      .get<ListingResults>(this.urlListhub + '/listings', {
        params,
        headers,
      })
      .pipe(map((res) => this._getDataFromListhubDB(res)));
  }

  getNearByListings(latitude: number | string, longitude: number | string, distance: number | string) {
    let headers = new HttpHeaders();
    headers = headers.append('x-api-key', this.hubAPIKey);

    let params = new HttpParams();
    params = params.append('latitude', latitude);
    params = params.append('longitude', longitude);
    params = params.append('distance', distance);

    let query = this.urlParserService.urlObject;
    for (let key in query) {
      params = params.append(key, query[key]);
    }
    if (!params.get('page')) {
      params = params.append('page', '1');
    }

    return this._http
      .get<ListingResults>(this.urlListhub + '/listings', {
        params,
        headers,
      })
      .pipe(map((res) => this._getDataFromListhubDB(res)));
  }

  getFavoritedListings(ids: string[], sort: string) {
    if (ids.length == 0) return of(null);
    return this.getListingsByIds(ids, sort);
  }

  searchLocations(str: string) {
    const url = environment.HUBAPI_BASE_URL + '/listhub-api/search-address';
    return this._http.get(url, {
      params: {
        input: str,
      },
    });
  }

  // private _sortByKey<T>(array: Array<T>, key: keyof typeof _orderMapper2): Array<T> {
  //   var first = key.charAt(0);
  //   // var format_key = key.substring(1); //remove first character
  //   var format_key = _orderMapper2[key];
  //   return array.sort((a: any, b: any) => {
  //     var x = a[format_key];
  //     var y = b[format_key];
  //     if (first === '+') {
  //       //low to high
  //       return x < y ? -1 : x > y ? 1 : 0;
  //     } else {
  //       //default high to low
  //       return x > y ? -1 : x < y ? 1 : 0;
  //     }
  //   });
}
// }

/*
  private _getListingFromListhub(item): Listing {
    let listing = new Listing();
    let photos = [];
    item.Media.forEach((it, idx) => {
      photos.push({
        caption: null,
        large: it.MediaURL,
        medium: it.MediaURL,
        sequenceNumber: idx + 1,
        small: it.MediaURL,
        tags: null,
        xlarge: it.MediaURL,
      });
    });
    listing = {
      ...listing,
      address: item.UnparsedAddress,
      agents: {
        coListingAgent: {
          email: item.ListAgentEmail,
          id: item.ListAgentKey,
          licenseNumber: null,
          name: item.ListAgentFullName,
          phone: item.ListAgentOfficePhone,
          uid: item.ListAgentKey,
          office: {
            id: item.ListOfficeKey,
            name: item.ListOfficeName,
            phone: item.ListOfficePhone,
            uid: item.ListOfficeKey,
          },
        },
        coSellingAgent: {
          email: null,
          id: null,
          licenseNumber: null,
          name: '',
          office: null,
          phone: null,
          uid: null,
        },
        listingAgent: {
          email: item.ListAgentEmail,
          id: item.ListAgentKey,
          licenseNumber: null,
          name: item.ListAgentFullName,
          phone: item.ListAgentOfficePhone,
          uid: item.ListAgentKey,
          office: {
            id: item.ListOfficeKey,
            name: item.ListOfficeName,
            phone: item.ListOfficePhone,
            uid: item.ListOfficeKey,
          },
        },
        sellingAgent: null,
      },
      bathrooms: item.BathroomsTotalInteger,
      bedrooms: item.BedroomsTotal,
      city: item.PostalCity,
      country: item.Country,
      description: item.PublicRemarks,
      distance: null,
      features: [
        {
          Interior: [
            {
              Heating: item.Heating[0] || '',
              'A/C': item.Cooling[0] || '',
            },
          ],
        },
      ],
      formattedAddress: item.UnparsedAddress + ', ' + item.PostalCity + ', ' + item.PostalCode,
      geoData: {
        cityId: '',
        cityName: '',
        neighborhoodId: '',
        neighborhoodName: '',
        schoolDistrictId: '',
        schoolDistrictName: item.HighSchoolDistrict,
        zipId: '',
        zipName: '',
      },
      hero: {
        caption: null,
        large: '',
        medium: '',
        sequenceNumber: 1,
        small: '',
        tags: null,
        xlarge: '',
      },
      id: item.ListingKey,
      listDate: item.ModificationTimestamp,
      location: [Number(item.Longitude), Number(item.Latitude)],
      lotSizeDisplay: item.LotSizeArea,
      mlsId: item.ListingId,
      mlsListingId: item.ListingId,
      mlsUpdateDate: item.ModificationTimestamp,
      mlsBrokerName: item.CustomFields.BrokerName,
      mlsLogoUrl: item.CustomFields.ListBrokerageLogoUrl,
      photoCount: item.PhotosCount,

      photos: photos,
      summaryPhotos: photos.slice(0, 4),
      price: item.ListPrice,
      priceDisplay: '$' + Number(item.ListPrice).toLocaleString(),
      priceShort: Number(item.ListPrice / 1000).toLocaleString() + 'k',
      propertyType: item.PropertyType,
      propertySubType: item.PropertySubType,
      saleType: null,
      schools: {
        elementarySchool: item.ElementarySchool,
        highSchool: item.HighSchool,
        juniorHighSchool: item.MiddleOrJuniorSchool,
        schoolDistrict: item.HighSchoolDistrict,
      },
      score: null,
      squareFeet: item.LivingArea,
      state: item.StateOrProvince,
      status: (typeof item.StandardStatus === 'string' ? item.StandardStatus : item.StandardStatus[0]) || 'active',
      yearBuilt: item.YearBuilt,
      zip: item.PostalCode,
    };
    return listing;
  }
  */
/*
  private _getListingFromListhubDB(item): Listing {
    if (item && item.property.Media) {
      let listing = new Listing();
      let photos = [];
      item.property.Media.forEach((it, idx) => {
        photos.push({
          caption: null,
          large: it.MediaURL,
          medium: it.MediaURL,
          sequenceNumber: idx + 1,
          small: it.MediaURL,
          tags: null,
          xlarge: it.MediaURL,
        });
      });
      listing = {
        ...listing,
        address: item.property.UnparsedAddress,
        agents: {
          coListingAgent: {
            email: item.property.ListAgentEmail,
            id: item.property.ListAgentKey,
            licenseNumber: null,
            name: item.property.ListAgentFullName,
            phone: item.property.ListAgentOfficePhone,
            uid: item.property.ListAgentKey,
            office: {
              id: item.property.ListOfficeKey,
              name: item.property.ListOfficeName,
              phone: item.property.ListOfficePhone,
              uid: item.property.ListOfficeKey,
            },
          },
          coSellingAgent: {
            email: null,
            id: null,
            licenseNumber: null,
            name: '',
            office: null,
            phone: null,
            uid: null,
          },
          listingAgent: {
            email: item.property.ListAgentEmail,
            id: item.property.ListAgentKey,
            licenseNumber: null,
            name: item.property.ListAgentFullName,
            phone: item.property.ListAgentOfficePhone,
            uid: item.property.ListAgentKey,
            office: {
              id: item.property.ListOfficeKey,
              name: item.property.ListOfficeName,
              phone: item.property.ListOfficePhone,
              uid: item.property.ListOfficeKey,
            },
          },
          sellingAgent: null,
        },
        bathrooms: item.property.BathroomsTotalInteger,
        bedrooms: item.property.BedroomsTotal,
        city: item.property.PostalCity,
        country: item.property.Country,
        description: item.property.PublicRemarks,
        distance: null,
        features: [
          {
            Interior: [
              {
                Heating: item.property.Heating[0] || '',
                'A/C': item.property.Cooling[0] || '',
              },
            ],
          },
        ],
        formattedAddress:
          item.property.UnparsedAddress + ', ' + item.property.PostalCity + ', ' + item.property.PostalCode,
        geoData: {
          cityId: '',
          cityName: '',
          neighborhoodId: '',
          neighborhoodName: '',
          schoolDistrictId: '',
          schoolDistrictName: item.property.HighSchoolDistrict,
          zipId: '',
          zipName: '',
        },
        hero: {
          caption: null,
          large: '',
          medium: '',
          sequenceNumber: 1,
          small: '',
          tags: null,
          xlarge: '',
        },
        id: item.property.ListingKey,
        listDate: item.property.ModificationTimestamp,
        location: [Number(item.property.Longitude), Number(item.property.Latitude)],
        lotSizeDisplay: item.property.LotSizeArea,
        mlsId: item.property.ListingId,
        mlsListingId: item.property.ListingId,
        mlsUpdateDate: item.property.ModificationTimestamp,
        mlsBrokerName: item.property.CustomFields.BrokerName,
        mlsComplianceText: item.property.Disclaimer,
        mlsLogoUrl: item.property.CustomFields.ListBrokerageLogoUrl,
        photoCount: item.property.PhotosCount,

        photos: photos,
        summaryPhotos: photos.slice(0, 4),
        price: item.property.ListPrice,
        priceDisplay: '$' + Number(item.property.ListPrice).toLocaleString(),
        priceShort: Number(item.property.ListPrice / 1000).toLocaleString() + 'k',
        propertyType: item.property.PropertyType,
        propertySubType: item.property.PropertySubType,
        saleType: null,
        schools: {
          elementarySchool: item.property.ElementarySchool,
          highSchool: item.property.HighSchool,
          juniorHighSchool: item.property.MiddleOrJuniorSchool,
          schoolDistrict: item.property.HighSchoolDistrict,
        },
        score: null,
        squareFeet: item.property.LivingArea,
        state: item.property.StateOrProvince,
        status:
          (typeof item.property.StandardStatus === 'string'
            ? item.property.StandardStatus
            : item.property.StandardStatus[0]) || 'active',
        yearBuilt: item.property.YearBuilt,
        zip: item.property.PostalCode,
      };
      return listing;
    }
  }
  */
/*
  private _getDataFromListhubDB(data: { listings: string | any[]; page: any; total_elements: number }): ListingModel {
    let rtVal = new ListingModel();
    let contentListings = [];
    let page = parseInt(data.page);

    for (let i = 0; i < data.listings.length; i++) {
      const item = data.listings[i];
      contentListings.push(this._getListingFromListhubDB(item));
    }
    rtVal = {
      ...rtVal,
      pageNumber: page,
      elementsPerPage: contentListings.length,
      totalElements: data.total_elements,
      totalPages: Math.floor((data.total_elements - 1) / 20) + 1,
      listings: contentListings,
      nextLink: '',
    };
    return rtVal;
  }
  */
/*
  private _getDataFromListhub(data): ListingModel {
    let rtVal = new ListingModel();
    let contentListings = [];
    for (let i = 0; i < data.value.length; i++) {
      const item = data.value[i];
      contentListings.push(this._getListingFromListhub(item));
    }
    rtVal = {
      ...rtVal,
      pageNumber: data['page'] - 1 || 0,
      elementsPerPage: contentListings.length,
      totalElements: data['@odata.count'],
      totalPages: Math.floor((data['@odata.count'] - 1) / 20) + 1,
      listings: contentListings,
      nextLink: '',
    };
    return rtVal;
  }
  */
/*
  getListingById(id): Promise<Listing> {
    let headers = new HttpHeaders();
    headers = headers.append('x-api-key', this.hubAPIKey);
    return new Promise(async (resolver) => {
      let rt = await this._http
        .get(this.urlListhub + '/listings/' + id, {
          headers: headers,
        })
        .toPromise();
      resolver(this._getListingFromListhubDB(rt));
    });
  }
  */
/*
  async getListings(): Promise<ListingModel> {
    let params = new HttpParams();
    let headers = new HttpHeaders();

    headers = headers.append('x-api-key', this.hubAPIKey);
    params = params.append('$count', 'true');

    let query = this.urlParserService.urlObject;
    let pageNumber = query['page'] === undefined ? '0' : String(Number(query['page']) - 1);
    params = params.append('page', pageNumber);

    for (let key in query) {
      params = params.append(key, query[key]);
    }

    // let propertyTypes = [];
    // for (let key in query) {
    //   // if (key === 'city' || key === 'state') {
    //   //   filters += ' and ' + _apiMaper[key] + " '" + query[key] + "'";
    //   // } else if (query[key] && _apiMaper[key]) {
    //   //   filters += ' and ' + _apiMaper[key] + ' ' + query[key];
    //   // }
    //   if(key === 'propertyTypes') {
    //     params = params.append('propertyTypes', query[key])
    //   }
    // }
    // params = params.append(
    //   '$orderby',
    //   query.sort && _orderMaper[query.sort]
    //     ? _orderMaper[query.sort]
    //     : _orderMaper['default']
    // );
    // params = params.append('city', query['city'])
    // params = params.append('state', query['state'])
    // let pageNumber = query['number'] === undefined ? '0' : query['number'];
    // params = params.append('page', pageNumber)

    return this._http
      .get(this.urlListhub + '/listings', {
        params: params,
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return this._getDataFromListhubDB(res);
        })
      )
      .toPromise();
  }
  */
